<template>
  <!--Side Navbar-->
  <nav class="sidebar dark_sidebar" v-bind="$attrs">
    <div class="logo d-flex justify-content-between">
      <router-link rel="opener" class="large_logo" to="/"
        ><img
          src="@/assets/img/logo.png"
          alt="ProduceMart Logo"
      /></router-link>
      <router-link rel="opener" class="small_logo" to="/"
        ><img
          src="@/assets/img/main-logo.png"
          alt="ProduceMart Logo"
      /></router-link>
    </div>
    <ul id="sidebar_menu">
      <!--Dashboard-->
      <li class="">
        <router-link to="/supplier-dashboard/home" class="dashboardMainLink">
          <div class="nav_icon_small">
            <img src="@/assets/img/menu-icon/dashboard.png" />
          </div>
          <div class="nav_title">
            <span :class="path == '/supplier-dashboard/home' && 'active-nav'"
              >Dashboard</span
            >
          </div>
        </router-link>
      </li>
       <!--Help Video-->
       <li class="">
        <router-link to="/supplier-dashboard/help-video">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/help.png" />
            </div>
            <div class="nav_title">
              <span :class="path == '/supplier-dashboard/help-video' && 'active-nav'">Help Videos</span>
              <span style="margin-left: 10px; "><IcRoundFiberNew style="font-size: 32px; color:dodgerblue"/></span>
            </div>
          </a>
        </router-link>
      </li>

       <!--Notifications-->
       <li class="">
        <router-link to="/supplier-dashboard/all-notifications">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/notification.png" />
            </div>
            <div class="nav_title">
              <span :class="
              path == '/supplier-dashboard/all-notifications' && 'active-nav'
            " >Notifications</span>
            </div>
          </a>
        </router-link>

      </li>

       <!--Products-->
       <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/product.png" />
            </div>
            <div class="nav_title">
              <span>Products</span>
            </div>
          </a>
        </router-link>
        <ul>
          <li
            :class="
              path == '/supplier-dashboard/upload-product' && 'active-nav'
            "
          >
            <a @click="checkUser"
              ><a>Upload Product</a>
            </a>
          </li>
          <li
            :class="
              path == '/supplier-dashboard/published-products' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/published-products"
              ><a>Published Products</a></router-link
            >
          </li>
          <li
            :class="
              path == '/supplier-dashboard/pending-products' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/pending-products"
              ><a>Pending Products</a></router-link
            >
          </li>
          <!-- <li>
            <router-link to="/supplier-dashboard/rejected-products"
              ><a>Rejected Products</a></router-link
            >
          </li> -->
          <li
            :class="
              path == '/supplier-dashboard/unavailable-products' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/unavailable-products"
              ><a>Unavailable Products</a></router-link
            >
          </li>
          <!-- <li
            :class="
              path == '/supplier-dashboard/draft-products' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/draft-products"
              ><a>Draft Products</a></router-link
            >
          </li> -->
        </ul>
      </li>

      <!--Orders-->
      <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/order.png" />
            </div>
            <div class="nav_title">
              <span>Orders</span>
            </div>
          </a>
        </router-link>
        <ul>
          <li
            :class="path == '/supplier-dashboard/open-orders' && 'active-nav'"
          >
            <router-link to="/supplier-dashboard/open-orders"
              ><a>Open Orders</a></router-link
            >
          </li>
          <li
            :class="path == '/supplier-dashboard/closed-orders' && 'active-nav'"
          >
            <router-link to="/supplier-dashboard/closed-orders"
              ><a>Closed Orders</a></router-link
            >
          </li>
        </ul>
      </li>

      <!--Bid product-->
      <li class="">
        <router-link to="/supplier-dashboard/bid-product" >
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/bid.png" />
            </div>
            <div class="nav_title">
              <span :class="path == '/supplier-dashboard/bid-product' && 'active-nav'"
              >Bids  </span
            >
            <!-- <span style="margin-left: 10px;"><FaWarning style="color: red; font-size: larger;"/></span> -->
            </div>
          </a>
        </router-link>
      </li>

      <!--Organization-->
      <li class="">
        <router-link to="/supplier-dashboard/add-organization-details" >
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/seller.png" />
            </div>
            <div class="nav_title">
              <span :class="path == '/supplier-dashboard/add-organization-details' && 'active-nav'"
              >Organization & Bank </span
            ><span style="margin-left: 10px;" v-if="tokenSet"><BxsBadgeCheck style="color: green; font-size: larger;"/></span><span style="margin-left: 10px;" v-else><FaWarning style="color: red; font-size: larger;"/></span>
            </div>
          </a>
        </router-link>
      </li>






      <!--Certifications-->
      <!-- <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/order.png" />
            </div>
            <div class="nav_title">
              <span>Audit</span>
            </div>
          </a>
        </router-link>
        <ul>
          <li
            :class="path == '/supplier-dashboard/request-audit' && 'active-nav'"
          >
            <router-link to="/supplier-dashboard/request-audit"
              ><a>Request Audit</a></router-link
            >
          </li>
          <li
            :class="path == '/supplier-dashboard/view-supplier-audit' && 'active-nav'"
          >
            <router-link to="/supplier-dashboard/view-supplier-audit"
              ><a>View Audit</a></router-link
            >
          </li>
          <li
            :class="path == '/supplier-dashboard/uploadCertificate' && 'active-nav'"
          >
            <router-link to="/supplier-dashboard/uploadCertificate"
              ><a>Upload Certificate</a></router-link
            >
          </li>
        </ul>
      </li> -->
      <!--Sales Analytics-->
      <!-- <li class="">
        <router-link to="/supplier-dashboard/sales-analytics">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/sales-analysis.png" />
            </div>
            <div class="nav_title">
              <span
                :class="
                  path == '/supplier-dashboard/sales-analytics' && 'active-nav'
                "
                >Sales Analytics</span
              >
            </div>
          </a>
        </router-link>
      </li> -->
      <!--Addresses-->
      <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/address.png" />
            </div>
            <div class="nav_title">
              <span>Addresses</span>
              <span style="margin-left: 10px;" v-if="checkAddress"><BxsBadgeCheck style="color: green; font-size: larger;"/></span><span style="margin-left: 10px;" v-else><FaWarning style="color: red; font-size: larger;"/></span>
            </div>
          </a>
        </router-link>
        <ul>
          <li
            :class="
              path == '/supplier-dashboard/add-new-address' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/add-new-address"
              ><a>Add New Address</a></router-link
            >
          </li>
          <li
            :class="path == '/supplier-dashboard/View-address' && 'active-nav'"
          >
            <router-link to="/supplier-dashboard/View-address"
              ><a>View All Address</a></router-link
            >
          </li>
        </ul>
      </li>
      <!--Settings-->
      <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/setting.png" />
            </div>
            <div class="nav_title">
              <span>Settings</span>
            </div>
          </a>
        </router-link>
        <ul>
          <li
            :class="
              path == '/supplier-dashboard/edit-profile-details' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/edit-profile-details"
              ><a>Edit Profile Details</a></router-link
            >
          </li>
          <li
            :class="
              path == '/supplier-dashboard/change-password' && 'active-nav'
            "
          >
            <router-link to="/supplier-dashboard/change-password"
              ><a>Change Password</a></router-link
            >
          </li>
          <!-- <li :class="path == '/supplier-dashboard/2fa-auth-settings' && 'active-nav'"><router-link to="/supplier-dashboard/2fa-auth-settings"><a>Two Factor Auth</a></router-link></li> -->
        </ul>
      </li>

        <!--Log Out-->
        <li class="">
          <router-link to="#">
            <a @click="logOut" aria-expanded="false">
              <div class="nav_icon_small">
                <img src="@/assets/img/menu-icon/logout.png">
              </div>
              <div class="nav_title">
                <span>Log Out</span>
              </div>
            </a>
          </router-link>
        </li>
    </ul>
  </nav>
  <!--Ends(side navbar)-->
</template>
<style scoped src="@/assets/vendors/themefy_icon/themify-icons.css"></style>
<style scoped src="@/assets/vendors/niceselect/css/nice-select.css"></style>
<style scoped src="@/assets/css/style.css"></style>
<style scoped src="@/assets/css/styleSupport.css"></style>
<script>
  import Swal from 'sweetalert2'
  import {head_script, del_script} from "@/assets/js/external";
  import { API } from '@/util/APIroute';
import { socket } from '@/util/socket';
import BxsBadgeCheck from '~icons/bxs/badge-check';
import FaWarning from '~icons/fa/warning';
// import FoundationBurstNew from '~icons/foundation/burst-new';
import IcRoundFiberNew from '~icons/ic/round-fiber-new';
export default {
  name: "dashboard",
  components: {
    "BxsBadgeCheck": BxsBadgeCheck,
    "FaWarning": FaWarning,
    "IcRoundFiberNew": IcRoundFiberNew
  },
  computed: {
    currentUser() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      console.log(user);
      if (user) {
        console.log(user);
        return user;
      }
    },
  },
  mounted() {
    // if (!this.currentUser) {
    //     this.$router.push('/login');
    // }

    window.scrollTo(0, 0);
    // this.getAdresses();

    // let externalScriptJquery = document.createElement("script");
    // let externalScriptMetisMenu = document.createElement("script");
    // let externalScriptCustom = document.createElement("script");

    // // externalScriptJquery.setAttribute(
    // //   "src",
    // //   "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
    // // );
    // externalScriptMetisMenu.setAttribute(
    //   "src",
    //   "https://cdn.statically.io/gh/NathTimi/Mart-script/main/metisMenu.js"
    // );
    // externalScriptCustom.setAttribute(
    //   "src",
    //   "https://cdn.statically.io/gh/morshud/JsFiles/main/custom.js"
    // );

    // // document.head.appendChild(externalScriptJquery)
    //     document.head.appendChild(externalScriptMetisMenu)
    //     document.head.appendChild(externalScriptCustom)
    head_script('/metisMenu.js');
    head_script('/custom.js');
    this.tokenSet = JSON.parse(sessionStorage.getItem("tradeToken")) ? JSON.parse(sessionStorage.getItem("tradeToken")) : false
    this.checkAddress = JSON.parse(sessionStorage.getItem("user")) && JSON.parse(sessionStorage.getItem("user")).businessAddress.length > 0 ? true : false
  },
  unmounted() {
    del_script('/custom.js');
    del_script('/metisMenu.js');
  },
  data() {
    return {
      path: this.$route.path,
      user: JSON.parse(sessionStorage.getItem("user")),
      address: [],
      tokenSet: false,
      checkAddress: false
    };
  },
  methods: {
    // async getAdresses() {
    //   const res = await fetch(
    //     `${API}/supplier/${this.user._id}/getUserAddress`,
    //     {
    //       method: "GET",
    //       headers: {
    //         Authorization: this.user.token,
    //       },
    //     }
    //   );
    //   const { data,message, status } = await res.json();
    //     if(status){
    //       this.address = data;
    //     }
    // },
    async checkUser(){
      if(this.user){
        const res = await fetch(
        `${API}/supplier/${this.user._id}/getUserAddress`,
        {
          method: "GET",
          headers: {
            Authorization: this.user.token,
          },
        }
      );
      const { data,message, status } = await res.json();
        if(status){
          this.address = data;
          // let userStatus;
          // if(this.address.length > 0){
          //   userStatus = this.address[0].user.status
          //     // console.log(userStatus)
          // }else{
          //   userStatus = this.user.status
          // }

          if(this.address.length === 0){
            Swal.fire({
                title: '<strong>Unauthorised</strong>',
                icon: 'info',
                html: '<ul> ' +
                  '<p style= "text-align: left;"><strong>Make sure you have completed the following: </strong></p>' +
                  '<li style ="text-align: left; text-decoration-style: none;"><strong>Added your company Address.</strong></li>'+
                  '</ul>',
                showCloseButton: true,
              })
          }else{
            this.$router.push("/supplier-dashboard/upload-product")
          }
      }else{
        this.address = [];
        if(this.address.length === 0){
            Swal.fire({
                title: '<strong>Unauthorised</strong>',
                icon: 'info',
                html: '<ul> ' +
                  '<p style= "text-align: left;"><strong>Make sure you have completed the following: </strong></p>' +
                  '<li style ="text-align: left; text-decoration-style: none;"><strong>Added your company Address.</strong></li>'+
                  '</ul>',
                showCloseButton: true,
              })
          }else{
            this.$router.push("/supplier-dashboard/upload-product")
          }
      }
      // this.$router.push("/supplier-dashboard/upload-product")
      }
    },
    logOut() {
      // if(user){
      //   socket.emit('disconnect', user._id)
      // }
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  }
};
</script>
