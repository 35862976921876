<template>

     <header class="mainHeader">
    <div>
       <nav class="navbar navbar-expand-lg navbar-light navbar-color">
        <div class="container">
            <div class="authDiv support mobileAuthDiv">
                <div v-if="user && userLogin == 'buyer'">

                    <div class="profile_info" style="left: -13px;">
                      <a type="button" @click="showDataNow" style="background: transparent !important;color: #000 !important;">
                        <img class="avatar" :src="user.img_url" alt="#" v-if="user.img_url" />
                        <img class="avatar" src="@/assets/img/client_img.png" alt="#" v-else />
                        Hi <!-- <span style="text-transform: capitalize;">{{ user.firstname }}</span> --> <i class="bi bi-caret-down"></i>
                      </a>

                      <div v-if="showData == true" class="profile_info_iner" style="z-index: 9999;left: -6px;">
                        <div class="profile_author_name">
                          <p style="font-size: 15px;">Buyer Account</p>
                          <h5>{{ user.firstname }} {{ user.lastname[0] }}.</h5>
                          <p style="margin-top:0;font-size: 12px;font-weight: 500;">{{user.company_name}}</p>
                        </div>
                        <div class="profile_info_details">
                          <div class="small_nav">
                          <a :href="$router.resolve({name: 'DashBuyerHome'}).href"  style="background: transparent !important;color: #000 !important;">
                            <img src="@/assets/img/menu-icon/dashboard.png" style="border: 0;border-radius: 0;margin-right: 10px; width: 15px; margin-left: -17px !important;background: transparent !important;color: #000 !important;" > Dashboard
                          </a>
                          </div>
                          <div class="small_nav">
                          <a :href="$router.resolve({name: 'DashBuyerProfile'}).href"  style="background: transparent !important;color: #000 !important;"
                            ><i style="margin-right: 8px;font-size: 20px; margin-left: -17px !important;background: transparent !important;color: #000 !important;" class="bi bi-person-circle"></i> My Profile</a
                          >
                          </div>
                          <div class="small_nav">
                          <a @click.prevent="logOut"  style="background: transparent !important;color: #000 !important;cursor: pointer">
                            <img src="@/assets/img/menu-icon/logout.png" style="border: 0;border-radius: 0;margin-right: 10px; width: 15px; margin-left: -17px !important;background: transparent !important;color: #000 !important;"  /> Log Out
                          </a>
                          </div>


                      </div>
                    </div>
                    </div>
                </div>
                <div v-else-if="user && userLogin == 'supplier'">


                    <div class="profile_info" style="left: -13px;">
                      <img class="avatar" :src="user.img_url" alt="#" v-if="user.img_url" />
                      <img class="avatar" src="@/assets/img/client_img.png" alt="#" v-else />
                      Hi<!-- , <span style="text-transform: capitalize;">{{ user.firstname }}</span> --> <i class="bi bi-caret-down"></i>
                      <div class="profile_info_iner" style="z-index: 9999;left: -6px;">
                        <div class="profile_author_name">
                          <p style="font-size: 15px;">Supplier Account</p>
                          <h5>{{ user.firstname }} {{ user.lastname[0] }}.</h5>
                          <p style="margin-top:0;font-size: 12px;font-weight: 500;">{{user.company_name}}</p>
                        </div>
                        <div class="profile_info_details">
                          <div class="small_nav">
                            <a :href="$router.resolve({name: 'DashSupplierHome'}).href" style="background: transparent !important;color: #000 !important;">
                            <img src="@/assets/img/menu-icon/dashboard.png" style="border: 0;border-radius: 0;margin-right: 10px;"> Dashboard
                          </a>
                          </div>
                          <div class="small_nav">
                          <a :href="$router.resolve({name: 'DashSupplierProfile'}).href" style="margin-left: -20px !important;background: transparent !important;color: #000 !important;"
                            ><i style="margin-right: 8px;font-size: 20px;" class="bi bi-person-circle"></i> My Profile</a
                          >
                          </div>
                          <div class="small_nav">
                            <a @click.prevent="logOut" style="background: transparent !important;color: #000 !important; cursor: pointer">
                            <img src="@/assets/img/menu-icon/logout.png" style="border: 0;border-radius: 0;margin-right: 10px; width: 15px;"  /> Log Out
                          </a>
                          </div>

                        </div>
                      </div>
                      </div>

                </div>
                <div v-else style="display:inline-flex">
                  <a :href="$router.resolve({name: 'Login'}).href" ><a class="mobileLogin">Login</a></a>
                  <!-- <SignUp/> -->

                  <a :href="$router.resolve({name: 'Registration'}).href"><a class="mobileSignup">Sign Up Free</a></a>
                </div>

            </div>
            <a class="navbar-brand lastest" rel="opener" href="/">
                <img src="@/assets/img/logo.png">
            </a>
            <!-- <a class="headerSearchMobile" type="button" role="button" @click="toggleMobileDropDown">
              <span style="color: #000;background: #97F29F;padding: 0px 6px;">
                  <i class="bi bi-search" style="font-size: 14px;"></i>
              </span>
            </a> -->

            <button class="navbar-toggler" type="button" @click.prevent="showOffcanvasMenu">
                <i class="bi bi-text-right"></i>
            </button>
            <div class="offcanvas offcanvas-end" :class="showMenu ? 'show' : ''" tabindex="-1" :style="{ visibility: showMenu ? 'visible' : 'hidden' }">
                <div class="offcanvas-header">
                    <div class="offcanvasImg">
                        <a class="navbar-brand" href="/">
                            <img src="@/assets/img/pmlg.png">
                        </a>
                    </div>
                    <button type="button" class="btn-offcanvas-close" @click.prevent="closeShowOffcanvasMenu">
                        <i class="bi bi-x-lg"></i>
                    </button>
                </div>
                <div class="offcanvas-body" style="opacity:none !important;">
                    <ul class="navbar-nav me-auto mb-2 mb-lg-0" >
                        <!-- <li class="nav-item">
                            <router-link to="/" rel="opener" class="route-link"><a class="nav-link"></a></router-link>
                        </li> -->
                        <li class="nav-item">
                            <router-link to="/" rel="opener"  class="route-link nav-link">
                              <div class="nav_title">
                              <span :class="path == '/' && 'active-nav'">Home</span>
                             </div>
                        </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/about" class="route-link nav-link"><div class="nav_title">
                              <span :class="path == '/about' && 'active-nav'">About Us</span>
                             </div></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/products" class="route-link"><a class="nav-link"><div class="nav_title">
                              <span :class="path == '/products' && 'active-nav'">View Products</span>
                             </div></a></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/request-a-product" class="route-link"><a class="nav-link">
                              <div class="nav_title">
                                <span :class="path == '/request-a-product' && 'active-nav'">Request A Product</span>
                               </div>
                            </a></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/blog" class="route-link"><a class="nav-link"><div class="nav_title">
                              <span :class="path == '/blog' && 'active-nav'">Blog</span>
                             </div></a></router-link>
                        </li>
                        <li class="nav-item">
                          <router-link class="route-link" to="/faq"><a class="nav-link"><div class="nav_title">
                            <span :class="path == '/faq' && 'active-nav'">FAQs</span>
                           </div></a></router-link>
                        </li>
                        <!-- <li class="nav-item">
                          <router-link class="route-link" to="/buyer-registration"><a class="nav-link"><div class="nav_title">
                            <span :class="path == '/buyer-registration' && 'active-nav'">Sign Up Free</span>
                           </div></a></router-link>
                        </li> -->

                        <!-- <li class="nav-item">
                          <a class="route-link">
                          <a @click="closeShowOffcanvasMenu()" class="nav-link" data-bs-toggle="modal" data-bs-target="#quote-modal" href="#">Contact Us</a>
                        </a>
                      </li> -->
                        <li>
                          <div class="authDiv desktopAuthDiv searchMobile">
                            <searchInner />
                          </div>
                        </li>
                        <div class="dropdown">
                          <a class="" type="button" role="button" @click="toggleDropDown">
                            <a class="nav-link">
                              <div class="leftoo">
                                  <i class="bi bi-search"></i>
                              </div>
                            </a>
                          </a>
                          <div v-if="dropdowndd" style="top: 15px;position: relative;">
                            <searchInner  />
                          </div>
                        </div>
                    </ul>



                    <div class="authDiv desktopAuthDiv ml-3">

                        <div v-if="user && userLogin == 'buyer'" class="header_iner">
                              <!--Notification-->
                              <div
                              class="header_right d-flex justify-content-between align-items-center"
                            >
                            <div class="header_notification_warp d-flex align-items-center">
                              <li>
                                <router-link
                                  class="bell_notification_clicker"
                                  to="/buyer-dashboard/all-notifications"
                                  v-if="notifications"
                                >
                                  <img src="@/assets/img/icon/bell.svg" alt="" />
                                  <span :class="notifications && 'ifNotification'">{{
                                    notifications.filter((prod) => !prod.read).length
                                  }}</span>
                                </router-link>

                              </li>
                            </div>


                            <div class="profile_info nav-link" style="margin-left: 50px;">
                              <img class="avatar" :src="user.img_url" alt="#" v-if="user.img_url" />
                              <img class="avatar" src="@/assets/img/client_img.png" alt="#" v-else />
                              Hi, <span style="text-transform: capitalize; cursor: pointer">{{ user.firstname }}</span> <i class="bi bi-caret-down"></i>
                              <div class="profile_info_iner">
                                <div class="profile_author_name">
                                  <p style="font-size: 15px;">Buyer Account</p>
                                  <h5>{{ user.firstname }} {{ user.lastname[0] }}.</h5>
                                  <p style="margin-top:0;font-size: 12px;font-weight: 500;">{{user.company_name}}</p>
                                </div>
                                <div class="profile_info_details">
                                  <div class="small_nav">
                                     <a :href="$router.resolve({name: 'DashBuyerHome'}).href"   >
                                    <img src="@/assets/img/menu-icon/dashboard.png" style="border: 0;border-radius: 0;margin-right: 10px;"> Dashboard
                                  </a>
                                  </div>

                                  <div class="small_nav">
                                    <a :href="$router.resolve({name: 'DashBuyerProfile'}).href" style="margin-left: -17px !important;"
                                    ><i style="margin-right: 8px;font-size: 20px;" class="bi bi-person-circle"></i> My Profile</a
                                  >
                                  </div>
                                  <div class="small_nav">
                                      <a @click.prevent="logOut" style="cursor: pointer">
                                          <img src="@/assets/img/menu-icon/logout.png" style="border: 0;border-radius: 0;margin-right: 10px; width: 15px;"  /> Log Out
                                        </a>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                            <!-- <a style="cursor: pointer" @click="logOut" class="authSignup">Logout</a> -->
                        </div>
                        <div v-else-if="user && userLogin == 'supplier'" class="header_iner">
                          <div
                              class="header_right d-flex justify-content-between align-items-center"
                            >
                              <!--Notification-->
                            <div class="header_notification_warp d-flex align-items-center">
                              <li>
                                <router-link
                                  class="bell_notification_clicker"
                                  to="/supplier-dashboard/all-notifications"
                                  v-if="notifications"
                                >
                                  <img src="@/assets/img/icon/bell.svg" alt="" />
                                  <span :class="notifications && 'ifNotification'">{{
                                    notifications.filter((prod) => !prod.read).length
                                  }}</span>
                                </router-link>

                              </li>
                            </div>
                            <div class="profile_info nav-link" style="margin-left: 50px;">
                              <img class="avatar" :src="user.img_url" alt="#" v-if="user.img_url" />
                              <img class="avatar" src="@/assets/img/client_img.png" alt="#" v-else />
                              Hi, <span style="text-transform: capitalize; cursor: pointer">{{ user.firstname }}</span> <i class="bi bi-caret-down"></i>
                              <div class="profile_info_iner">
                                <div class="profile_author_name">
                                  <p style="font-size: 15px;">Supplier Account</p>
                                  <h5>{{ user.firstname }} {{ user.lastname[0] }}.</h5>
                                  <p style="margin-top:0;font-size: 12px;font-weight: 500;">{{user.company_name}}</p>
                                </div>
                                <div class="profile_info_details">
                                  <div class="small_nav">
                                  <a :href="$router.resolve({name: 'DashSupplierHome'}).href">
                                    <img src="@/assets/img/menu-icon/dashboard.png" style="border: 0;border-radius: 0;margin-right: 10px;"> Dashboard
                                  </a>
                                  </div>
                                  <div class="small_nav">
                                  <a :href="$router.resolve({name: 'DashSupplierProfile'}).href" style="margin-left: -17px !important;"
                                    ><i style="margin-right: 8px;font-size: 20px;" class="bi bi-person-circle"></i> My Profile</a
                                  >
                                  </div>
                                  <div class="small_nav">
                                    <a @click.prevent="logOut" style="cursor: pointer">
                                    <img src="@/assets/img/menu-icon/logout.png" style="border: 0;border-radius: 0;margin-right: 10px; width: 15px;"  /> Log Out
                                  </a>
                                  </div>

                                </div>
                              </div>
                            </div>
                            </div>
                            <!-- <a style="cursor: pointer" @click="logOut" class="authSignup">Logout</a> -->
                        </div>
                        <div class="nav-link" v-else>
                            <a :href="$router.resolve({name: 'Login'}).href"><a class="authLogin">Login</a></a>
                            <!-- <SignUp/> -->
                            <a :href="$router.resolve({name: 'Registration'}).href"><a class="authSignup">Sign Up Free</a></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </nav>
    <!-- <div v-if="mobiledropdown == true">
      <SearchHeader />
    </div> -->

    </div>

    </header>



   <!-- Feedback modal starts -->
   <div class="modal fade full-width-modal quote-modal" data-bs-backdrop="static" data-bs-keyboard="false" id="feedback-modal" ref="feedback-modal" tabindex="-1" role="dialog" aria-hidden="true" style="z-index: 9999;">
    <div class="modal-dialog model-lg modal-dialog-centered" role="document">
      <div class="feed modal-content d-flex align-items-center justify-content-center text-center white-bg">
          <div class="modal-header">
              <button type="button" class="button close" data-bs-dismiss="modal" aria-label="Close">
              <!-- <i class="fas fa-times"></i> -->
              <fa6-solid-xmark/>
              </button>
              <!-- End of .close -->
          </div>
          <!-- End of .modal-header -->

          <div class="modal-body d-flex align-items-center justify-content-center text-center">
              <div class="quote-form-wrapper text-center">
                <div class="loginDiv">
                  <h3>Feedback</h3>

                  <form id="feedbackForm" @submit.prevent class="quote-form text-center row">
                    <div class="col-lg-6">
                      <label for="name"> name: </label>
                        <input v-model="Email.name" type="text" name="user_name" placeholder="Name" required>
                    </div>
                    <div class="col-lg-6">
                      <label for="email">Email: </label>
                        <input v-model="Email.email" type="text" name="user_email" placeholder="Email" required>
                    </div>

                    <div class="col-lg-12">
                      <label for="suggestion">I would like to suggest the following:</label>
                        <textarea v-model="Email.suggestion" type="text" name="suggestion" placeholder="Suggestion"></textarea>
                    </div>
                    <div class="col-lg-12">
                      <label for="role"> Are you a Buyer or Supplier?: </label>
                        <select v-model="Email.role">
                          <option disabled value="">Please select one</option>
                          <option value="Buyer"> Buyer </option>
                          <option value="Supplier">Supplier</option>
                        </select>
                    </div>
                    <div class="col-lg-12">
                      <label for="message">Additional Details: </label>
                        <textarea v-model="Email.message" placeholder="Message" name="message" required></textarea>

                        <div>
                          <vue-recaptcha ref="recaptcha" :siteKey="siteKey"
                          :sitekey="siteKey" @verify="onSubmit1" :loadRecaptchaScript='true'
                          @expired="onCaptchaExpired"></vue-recaptcha>
                          <button @click="send" type="submit" value="Submit" class="submit custom-btn secondary-btn w-100" :disabled="isLoading" >Send</button>
                           <div
                            class="col-lg-12 mb-4 mt-2 text-center signuas"
                            v-show="isLoading"
                          >
                            <span class="spinner-border spinner-border-sm"></span>
                          </div>
                        </div>


                        <div class="social-icons-wrapper d-flex justify-content-center">
                            <p>Follow us:</p>
                            <ul class="social-icons">
                                <li>
                                    <a href="https://www.facebook.com/producemartcom" target="_blank" rel="noopener">
                                        <!-- <i class="fab fa-facebook-f"></i> -->
                                        <fa6-brands-facebook-f/>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/producemartcom" target="_blank" rel="noopener">
                                        <!-- <i class="fab fa-twitter"></i> -->
                                        <fa6-brands-twitter/>
                                    </a>
                                </li>

                                <li>
                                    <a href="https://www.instagram.com/producemartdotcom/" target="_blank" rel="noopener">
                                        <!-- <i class="fab fa-google-plus-g"></i> -->
                                        <fa6-brands-instagram/>
                                    </a>
                                </li>

                              <li>
                                  <a href="https://www.linkedin.com/company/producemart-com/about/?viewAsMember=true" target="_blank" rel="noopener">
                                      <!-- <i class="fab fa-behance"></i> -->
                                      <fa6-brands-linkedin/>
                                  </a>
                              </li>
                              <li>
                                  <a href="https://www.youtube.com/channel/UCAH9Y-rOGCljrYMN7JNHO4g/featured" target="_blank" rel="noopener">
                                      <!-- <i class="fab fa-behance"></i> -->
                                      <fa6-brands-youtube/>
                                  </a>
                              </li>
                            </ul>
                            <!-- End of .social-icons -->
                        </div>
                        <!-- End of .social-icons-wrapper -->
                    </div>
                </form>
                  <!-- End of .quote-form -->
                </div>

              </div>
              <!-- End of .quote-form-wrapper -->

          </div>

          <!-- End of .modal-body -->
      </div>
      <!-- End of .modal-content -->
  </div>
  <!-- End of .modal-dialog -->
   </div>
   <!-- End of .quote-modal -->

   <!-- Help modal starts -->
   <div class="modal fade full-width-modal quote-modal" ref="help-modal" id="help-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true"  style="z-index: 9999;">
    <div class="modal-dialog model-lg modal-dialog-centered" role="document">
      <div class="feed modal-content d-flex align-items-center justify-content-center text-center white-bg">
          <div class="modal-header">
              <button type="button" class="button close" data-bs-dismiss="modal" aria-label="Close">
              <!-- <i class="fas fa-times"></i> -->
              <fa6-solid-xmark/>
              </button>
              <!-- End of .close -->
          </div>
          <!-- End of .modal-header -->

          <div class="modal-body d-flex align-items-center justify-content-center text-center">
              <div class="quote-form-wrapper text-center">
                <div class="loginDiv">
                  <h3>Get Help</h3>
                  <form id="helpForm" @submit.prevent class="quote-form text-center row">
                      <div class="col-lg-6">
                        <label for="name"> name: </label>
                          <input v-model="Email.name" type="text" name="user_name" placeholder="Name" required>
                      </div>
                      <div class="col-lg-6">
                        <label for="email">Email: </label>
                          <input v-model="Email.email" type="text" name="user_email" placeholder="Email" required>
                      </div>
                      <div class="col-lg-12">
                        <label for="message">Message: </label>
                          <textarea v-model="Email.message" placeholder="Message" name="message" required></textarea>


                          <div>
                            <vue-recaptcha ref="recaptcha" :siteKey="siteKey"
                            :sitekey="siteKey" @verify="onSubmit2" :loadRecaptchaScript='true'
                            @expired="onCaptchaExpired">
                          </vue-recaptcha>
                             <button @click="send" type="submit" value="Submit" class="submit custom-btn secondary-btn w-100" :disabled="isLoading" >Send</button>
                             <div
                              class="col-lg-12 mb-4 mt-2 text-center signuas"
                              v-show="isLoading"
                            >
                              <span class="spinner-border spinner-border-sm"></span>
                            </div>
                          </div>


                          <div class="social-icons-wrapper d-flex justify-content-center">
                              <p>Follow us:</p>
                              <ul class="social-icons">
                                  <li>
                                      <a href="https://www.facebook.com/producemartcom" target="_blank" rel="noopener">
                                          <!-- <i class="fab fa-facebook-f"></i> -->
                                          <fa6-brands-facebook-f/>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="https://twitter.com/producemartcom" target="_blank" rel="noopener">
                                          <!-- <i class="fab fa-twitter"></i> -->
                                          <fa6-brands-twitter/>
                                      </a>
                                  </li>

                                  <li>
                                      <a href="https://www.instagram.com/producemartdotcom/" target="_blank" rel="noopener">
                                          <!-- <i class="fab fa-google-plus-g"></i> -->
                                          <fa6-brands-instagram/>
                                      </a>
                                  </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/producemart-com/about/?viewAsMember=true" target="_blank" rel="noopener">
                                        <!-- <i class="fab fa-behance"></i> -->
                                        <fa6-brands-linkedin/>
                                    </a>
                                </li>
                                <li>
                                  <a href="https://www.youtube.com/channel/UCAH9Y-rOGCljrYMN7JNHO4g/featured" target="_blank" rel="noopener">
                                      <!-- <i class="fab fa-behance"></i> -->
                                      <fa6-brands-youtube/>
                                  </a>
                              </li>
                              </ul>
                              <!-- End of .social-icons -->
                          </div>
                          <!-- End of .social-icons-wrapper -->
                      </div>
                  </form>

                  <!-- End of .quote-form -->
                </div>

              </div>
              <!-- End of .quote-form-wrapper -->
              <!-- <div v-if="isLoading">
                <img :src="loading" alt="loader">
              </div> -->
          </div>

          <!-- End of .modal-body -->
      </div>
      <!-- End of .modal-content -->
  </div>
  <!-- End of .modal-dialog -->
   </div>
   <!-- End of .quote-modal -->

  <!-- <contactContent/> -->
</template>
<style scoped src="@/assets/css/styleFrontend.css"></style>
<style scoped src="@/assets/css/vendor/keep.css"></style>
<style scoped src="@/assets/css/bootstrap.css"></style>
<!-- <style scoped src="@/assets/css/vendor/bootstrap.min.css"></style> -->

<style scoped>

.header_iner .header_right .header_notification_warp {
  margin-left: 40px;
  background-color: #fff;
  background: #fff;
}

@media (max-width: 575.98px) {
  .header_iner .header_right .header_notification_warp {
    margin-right: 25px;
    margin-left: 20px;
    background-color: #fff;
  background: #fff;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header_iner .header_right .header_notification_warp {
    margin-right: 35px;
    margin-left: 25px;
    background-color: #fff;
  background: #fff;
  }


}

.header_iner .header_right .header_notification_warp li {
  display: inline-block;
  position: relative;
  background-color: #fff;
  background: #fff;
}


.header_iner .header_right .header_notification_warp li > a {
  position: relative;
  margin-right: 29px;
  background-color: #fff;
  background: #fff;
}

.header_iner .header_right .header_notification_warp li > a img {
  width: 17px;
  margin-top: 20px;
  background-color: #fff;
  background: #fff;
}

.header_iner .header_right .header_notification_warp li > a span {
  position: absolute;
  width: 22px;
  height: 22px;
  text-align: center;
  color: #fff;
  display: inline-block;
  background: #fff;
  font-size: 11px;
  border-radius: 50%;
  right: -12px;
  top: -3px;
  background: #000;
  line-height: 22px;
}

.ifNotification {
  background: rgb(216, 64, 64) !important;
}

@media (max-width: 575.98px) {
  .header_iner .header_right .select_style {
    display: none !important;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .header_iner .header_right .select_style {
    display: none !important;
  }
}

.header_iner .nice_Select {
  color: #929bb5;
}


  .small_nav{
    padding: 5px 8px 5px 8px;

    border-radius: 10px;
  }
  .small_nav:hover{
    background: #97F29F;
    cursor: pointer;
    color: #000
  }
  .active-nav {
    border-bottom: 2px solid #97f29f;
    padding-bottom: 1px;
  }
  .feed.modal-content{
    margin-top: 4rem;
  }

  @media screen and (max-width: 990px) {
    .social-icons-wrapper p {
      font-size: small;

    }

    .social-icons li a {
      font-size: small;
    }
  }
  .dropdown {
    display: inline;
    position: relative;
    justify-content: left;
    justify-self: left;
    align-content: left;
    margin-bottom: 10px;
  }
  .dropdown .dropdown-content {
    display: none;
    position: absolute;
    color: #97F29F;
    background: #000;
    width: 370px;
    overflow: auto;
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.4);

  }
  .dropdown button.dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: #97F29F;
    padding: 14px 16px;
    background-color: #000;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
  }
  .dropdown:hover .dropdown-content {
    display: block;
    margin: 50px 0px 50px 0px;
    width: 111px;
  }

  .dropdown .dropdown-content a {
    display: block;
    color: #97F29F;
    background: #000;
    padding: 5px;
    text-decoration: none;


  }
  .dropdown .dropdown-content a:hover {
    color: #FFFFFF;
    background-color: #00A4BD;
  }
  .modal-header{
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
  }
.modal-header .button{
  border: none;
  right: 300px

}
.modal-header .button svg{
  font-size: large;
  color: #000;
  right: 300px
}


.modal-body .quote-form-wrapper{
    width: 100%;
    max-width: 100%;
    margin: 20px auto;
    margin-top: 2px;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
    padding: 30px;
    border: 1px solid #000;
    border-radius: 20px;
    background: #97F29F;
}

.modal-body .quote-form-wrapper h3{
  text-transform: uppercase;
  color: #000;
}

.modal-body .quote-form-wrapper form input{
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  color: #000;
  text-indent: 10px;
  border-radius: 20px;

}
.modal-body .quote-form-wrapper form textarea::placeholder{
  font-size: small;
  color: #000;
  opacity: 0.5;
}
.modal-body .quote-form-wrapper form input::placeholder{
  font-size: small;
  color: #000;
  opacity: 0.5;
}
.modal-body .quote-form-wrapper form select{
  font-size: medium;
  background: #fff;
  color: #000;
  width: 100%;
  max-width: 100%;
  border-radius: 15px;
  padding: 5px 5px 5px 5px;
}
.modal-body .quote-form-wrapper textarea{
  height: 100px;
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  color: #000;
  border-radius: 10px;
  text-indent: 10px;
  border-radius: 20px;
}

.modal-body .quote-form-wrapper .submit{
  text-align: center;
  background: #000;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: #97F29F;
  border-radius: 20px;
}

.modal-body .quote-form-wrapper label{
  color: #000;
  display: flex;
  align-content: flex-start;
  justify-content: left;
  margin: 20px 15px 10px;
  font-size: 0.7em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  text-align: left;
}

.social-icons-wrapper p {
  font-size: small;
  line-height: 2rem;
  color: #000;
  font-weight: 700;
  margin: 1.2rem 0 1rem;
}

.social-icons {
  padding-left: .7rem;
  margin: 1.5rem 0 .8rem;
}

.social-icons li {
  display: inline-block;
  margin-right: 0.6rem;
  color: #000;
  -webkit-transition: all .5s;
  transition: all .5s;
}

.social-icons li a {
  font-size: small;
  color: currentColor;
  -webkit-transition: none;
  transition: none;
}

.social-icons li:hover {
  color: #fff;
}

h4{
  text-align: left;
  margin: 10px;
  text-transform: uppercase;
}


</style>
<style>
  .support{
      display: none;
  }
  @media (max-width:990px) {
    .support{
      display: block ;
    }
  }


</style>

<script setup>
 import { VueRecaptcha } from 'vue-recaptcha';
import Fa6BrandsYoutube from '~icons/fa6-brands/youtube'
import Fa6BrandsInstagram from '~icons/fa6-brands/instagram'
import Fa6BrandsLinkedin from '~icons/fa6-brands/linkedin'
import Fa6BrandsFacebookF from '~icons/fa6-brands/facebook-f'
import Fa6BrandsTwitter from '~icons/fa6-brands/twitter'
import Fa6SolidXmark from '~icons/fa6-solid/xmark'
import {setEmail} from '@/piniaStore/email'
import {onMounted, ref, onUnmounted} from 'vue';
import Swal from "sweetalert2"
import router from '@/router';
import { API, captcha } from "@/util/APIroute";
// import { ToastAlert } from '@/util/SweetAlertUtl';
import { socket } from '@/util/socket';

const Email = setEmail()
const user = ref(JSON.parse(sessionStorage.getItem('user')) ?? null)
const siteKey = ref(captcha)
const recaptcha = ref()
const notifications = ref([])





var events = ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load']

var warningTimer = ref(null);
var logoutTimer = ref(null);
var verify = ref(false)

var Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener('mouseenter', Swal.stopTimer)
    toast.addEventListener('mouseleave', Swal.resumeTimer)
  }
})


if(user.value){
  var getAllNotification = async() => {
    if(user.value && user.value.role === "buyer"){
      var res = await fetch(
        `${API}/buyer/${user.value._id}/getUserNotifications`,
        {
          method: "GET",
          headers: {
            Authorization: user.value.token,
          },
        }
      );
      const { data,message, status } = await res.json();
      if(status){
          notifications.value = data;
        }
    }else if( user.value && user.value.role === "supplier"){
          res = await fetch(
            `${API}/supplier/${user.value._id}/getUserNotifications`,
            {
              method: "GET",
              headers: {
                Authorization: user.value.token,
              },
            }
          );

          const { data,message, status } = await res.json();
        if(status){
          notifications.value = data;
        }

    }
    }
}



const onCaptchaExpired = () =>{
  Email.token = null
  recaptcha.value.reset()

}



const onSubmit1 = (response) =>{
  Email.token = response
  Email.sendToken().then((res) =>{
    if(res){
      verify.value = true
    }else{
      verify.value = false
      Email.token = null
      recaptcha.value.reset()
      if(Email.error){
        Toast.fire({
          icon: 'error',
          title: 'An error occurred'

        })
      }else{
        Toast.fire({
          icon: 'warning',
          title: 'Please try the reCaptcha again'

        })
      }
    }
  })
}

const onSubmit2 = (response) =>{
  Email.token = response
  Email.sendToken().then((res) =>{
    if(res){

      verify.value = true
    }else{
      Email.token = null
      verify.value = false
      recaptcha.value.reset()
      if(Email.error){

        Toast.fire({
          icon: 'error',
          title: 'An error occurred'

        })
      }else{
        Toast.fire({
          icon: 'warning',
          title: 'Please try the reCaptcha again'

        })
      }
    }
  })
}

// var warningZone = ref(false);
const warningMessage = () => {
if(user.value){
  Swal.fire({
  icon: 'info',
  title: 'Are you still with us?',
  showConfirmButton: true,
  confirmButtonText: 'Yes',
})

}else{
  resetTimers()

}

}

const logoutUser = () => {
if(user.value){
  // let timerInterval
      Swal.fire({
        title: 'You have now been logged out owing to inactivity',
        icon: "warning",
        timer: 5000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading()
          // const b = Swal.getHtmlContainer().querySelector('b')
          // timerInterval = setInterval(() => {
          //   b.textContent = (Swal.getTimerLeft() / 1000)
          // }, 1000)
        },
        // willClose: () => {
        //   clearInterval(timerInterval)
        // }
      }).then(async(result)=>{
        if (result.dismiss === Swal.DismissReason.timer || result.dismiss === Swal.DismissReason.backdrop) {
          const userId = JSON.parse(sessionStorage.getItem("user"))._id
          const res = await fetch(`${API}/logout/${userId}`)
          const data = await res.json()
          if(data.status){
            Toast.fire({
              title: data.message,
              icon: "success"
            }).then(()=>{
              socket.emit('disconnect', userId)
              sessionStorage.removeItem('user')
              router.go()
            })
          }else{
            Toast.fire({
              title: data.message,
              icon: "error"
            })
          }

        }
      })

}else{
  resetTimers()
}

}

var setTimers = () =>{

   warningTimer.value = setTimeout(warningMessage, 600000);
  logoutTimer.value = setTimeout(logoutUser, 900000);
  // warningZone.value = false


}

var resetTimer = () =>{
clearTimeout(warningTimer.value)
clearTimeout(logoutTimer.value)
setTimers()
}
var resetTimers = () =>{
clearTimeout(warningTimer.value)
clearTimeout(logoutTimer.value)
// setTimers()
}


const isLoading = ref(false)
const send = () => {
  if(!Email.name || !Email.email || !Email.message){
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Fill the required information!',
    })
  }else{
    if(verify.value){
      isLoading.value = true
      Email.send()
      .then(() =>{
        if(Email.status){
          isLoading.value = false
          verify.value = false
          recaptcha.value.reset()
          Swal.fire("Sent", Email.status, 'success')

        }
        if(Email.error){
          isLoading.value = false
          verify.value = false
          recaptcha.value.reset()
          Swal.fire("Oops...", "An error occured", 'error')
          console.log(Email.error)

        }
      })
    }

  }
}

onMounted(() =>{
  (user.value && user.value.role == "buyer") || (user.value && user.value.role == "supplier") ? getAllNotification() : ""
events.forEach(function (event){
      window.addEventListener(event, resetTimer)
    })

    setTimers()

    let audio = new Audio(require('../assets/tone/producemart.mp3'))
    audio.muted = true;

    socket.on('notification', (data) => {
      // ToastAlert("You have a new notification", "warning")
      //  audio.volume = 0.5
      // audio.muted = false;
      // audio.play();
      (user.value && user.value.role == "buyer") || (user.value && user.value.role == "supplier") ? getAllNotification() : ""
    })

    // socket.on('admin-notification', (data) => {
    //   // ToastAlert("You have a new notification", "warning")
    //   //  audio.volume = 0.5
    //   // audio.muted = false;
    //   // audio.play();
    //   getAllNotification();
    // })
})
onUnmounted(() =>{
  events.forEach(function (event){
      window.removeEventListener(event, resetTimer)
    })

    resetTimers()
})

</script>
<script>
    import SearchInner from './searchInner.vue'
    import SearchHeader from './searchHeader.vue'
    import SignUp from "@/components/UserDropdown.vue"

    export default {
      name: "ProduceMart",
      components:{
      'searchInner': SearchInner,
      SearchHeader,
      SignUp
      },
      data(){
        return {
            user: JSON.parse(sessionStorage.getItem("user")) || '',
            userLogin: '',
            dropdowndd: false,
            showData: false,
            mobiledropdown: false,
            showMenu: false,
            path: this.$route.path
        }
      },
      created(){

      },
      methods: {
        logOut() {
          if(this.$route.path === '/'){
            this.$store.dispatch("auth/logout");
            this.$router.go()
          }else{
            this.$store.dispatch("auth/logout");
            this.$router.push("/");
            this.$router.go()
          }
        },
        toggleDropDown(){

          this.dropdowndd = !this.dropdowndd;

        },
        showOffcanvasMenu(){
            this.showMenu  = true
        },
        closeShowOffcanvasMenu(){
            this.showMenu  = false
        },
        toggleMobileDropDown(){
          this.mobiledropdown = !this.mobiledropdown;
        },
        showDataNow(){
          this.showData = !this.showData
          //console.log(this.showData)
        },
        getUser(){
            //console.log(JSON.parse(sessionStorage.getItem("user")))
            if(this.user.role == 'buyer'){
                this.userLogin = 'buyer'
                //console.log(this.userLogin)
            }
            if(this.user.role == 'supplier'){
                this.userLogin = 'supplier'
                //console.log(this.userLogin)
            }

        }
      },
      mounted(){
        //window.scrollTo(0,0)
        this.getUser();
        window.addEventListener('click', (e) => {
          if (!this.$el.contains(e.target)){
            // this.dropdowndd = false
            this.mobiledropdown = false
          }
        })
      }
    }
</script>
