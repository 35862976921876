<template>
    <div>
        <button class="floating-btn" >
            <fa6-solid-comments/>
         </button>

            <ul class="float-child">
                <li class="float1-content">
                    <p @click="setFeedback" data-bs-toggle="modal" data-bs-target="#feedback-modal">FEEDBACK</p>
                    <button @click="setFeedback" data-bs-toggle="modal" data-bs-target="#feedback-modal"  class="float1">
                        <fa6-regular-pen-to-square/>
                    </button>
                </li>
                <li class="float2-content">
                    <p @click="setHelp" data-bs-toggle="modal" data-bs-target="#help-modal">GET HELP</p>
                    <button @click="setHelp" data-bs-toggle="modal" data-bs-target="#help-modal" class="float2">
                        <fa6-regular-circle-question/>
                    </button>
                </li>
                
            </ul> 
        
        
       
    </div>
   
</template>
<style scoped>
    .float-child{
        list-style-type: none;
        position:absolute;
        bottom: 70px;
        right: 0;
        opacity: 0;
        transition: all 0.3s;
        transform-origin: 80% bottom;
    }
    .floating-btn:focus+.float-child{
        opacity: 1; 
        
    }
    .floating-btn:not(:focus)+.float-child{
        cursor: none;
        visibility: hidden;
        
    }
    .floating-btn{
        width: 60px;
        height: 60px;
        background: #97F29F;
        display: flex;
        border-radius: 50%;
        color: #000;
        font-size: 30px;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
        position: fixed;
        right: 20px;
        bottom: 120px;
        transition: background 0.25s;
        outline: blue;
        border: none;
        cursor: pointer;
    }

    .float1-content{
        display: flex;
        position: fixed;
        right: 20px;
        bottom: 200px;
        align-items: center;
        justify-content: center;
    }

    .float1{
        width: 50px;
        height: 50px;
        background: #97F29F;
        border-radius: 50%;
        color: #000;
        font-size: 16px;
        text-decoration: none;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
        transition: background 0.25s;
        outline: blue;
        border: none;
        cursor: pointer;
    }
    .float1-content p{
        margin-right: 10px;
        background: #97F29F;
        color: #000;
        padding: 5px 8px 5px 8px;
        border-radius: 30px;
        font-weight: 700;
        cursor: pointer;
    }

    .float2-content{
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        right: 20px;
        bottom: 270px;
    }
    .float2{
        width: 50px;
        height: 50px;
        background: #97F29F;
        border-radius: 50%;
        color: #000;
        font-size: 16px;
        text-decoration: none;
        box-shadow: 2px 2px 5px rgba(0,0,0,0.25);
        transition: background 0.25s;
        outline: #000;
        border: none;
        cursor: pointer;
    }
    .float2-content p{
        margin-right: 10px;
        background: #97F29F;
        color: #000;
        padding: 5px 8px 5px 8px;
        border-radius: 30px;
        font-weight: 700;
        cursor: pointer;
    }

    .floating-btn:focus,
    .floating-btn:hover{
        background: #000;
        color: #97F29F;
    }

    .float1-content p:hover,
    .float1:hover{
        background: #000;
        color: #97F29F;
    }

    .float2-content p:hover,
    .float2:hover{
        background: #000;
        color: #97F29F;
    }
</style>
<script setup>
import {setEmail} from '@/piniaStore/email'
import Fa6SolidComments from '~icons/fa6-solid/comments'
import Fa6RegularPenToSquare from '~icons/fa6-regular/pen-to-square'
import Fa6RegularCircleQuestion from '~icons/fa6-regular/circle-question'

const Email = setEmail()

const setHelp = () => {
    Email.subject = 'getHelp'
}

const setFeedback = () =>{
    Email.subject = 'feedback'
}

// const buttonValue = ref(false)

// const tButton = () => {
//     buttonValue.value = !buttonValue.value
// }
</script>