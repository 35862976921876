<template>
  <title>FAQs - Frequently Asked Questions | ProduceMart</title>
  <mainHeader/>

  <!--Page Breadcrumb-->
  <section class="pageBreadcrumb faqPageBread">
      <div class="container">
          <div class="row justify-content-center">
                <div class="col-lg-10 text-center">
                    <div class="textHead">
                        <p class="subhead">ProduceMart</p>
                        <h2>Frequently Asked Questions (FAQs)</h2>
                        <!-- <p class="belowHead">Find answers to all questions you you need here!</p> -->
                    </div>
                </div>
          </div>
      </div>
  </section><!--Page Breadcrumb Ends-->

  <!--FAQ Content-->
  <main class="faqContent">
    <section class="questions faq-section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-10">
                    <div class="faq-box" v-if="faqs.length > 0">
                        <details v-for="faq in faqs">
                            <summary>{{faq.question}}
                            </summary>
                            <div class="faq-content">

                                <p v-html="faq.answer"></p>
                            </div>
                        </details>
                        <!-- <details>
                            <summary>Who can be a supplier on ProduceMart?
                            </summary>
                            <div class="faq-content">

                                <p>Suppliers on ProduceMart range from small to medium-sized commercial farmers, aggregators, produce suppliers, farmer cooperatives and much more!</p>
                            </div>
                        </details>
                        <details>
                            <summary>Who can be a buyer on ProduceMart?</summary>
                            <div class="faq-content">

                                <p>Buyers on ProduceMart can be food processors, manufacturers, co-packers, co-manufacturers, food &amp; produce aggregators, wholesalers, retailers and much more; some even resell the produce they source.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>What do I need to do as a supplier to list my products on Producemart?
                            </summary>
                            <div class="faq-content">

                                <p>All suppliers <strong> <u> must undergo</u></strong> an audit before listing products on ProduceMart. Three audit levels show a supplier's awareness of international food safety standards. The supplier is responsible for paying the cost of audits/certifications. ProduceMart will verify all supplier audits and certifications.</p>
                            </div>
                        </details>
                        <details>
                            <summary>Are Buyers verified?</summary>
                            <div class="faq-content">

                                <p>To initiate transactions, buyers pay the order amount into an escrow account to show commitment. Once settled into escrow, the payment can&#39;t be withdrawn by the buyer. This shows the suppliers that buyers are committed to the transaction. All payments into escrow are secure and won't be released to the suppliers unless the products are successfully verified to confirm quality and quantity. If the suppliers' products don&#39;t pass inspection or don&#39;t meet the buyers&#39; requirements, the escrow amount will be returned to the buyer. </p>
                            </div>
                        </details>
                        <details>
                            <summary>How are products verified?
                            </summary>
                            <div class="faq-content">

                                <p>Our international inspection partners, SGS, verify all product quality and quantity and issue a report. ProduceMart will only release the payment in escrow to suppliers if a product meets the expected requirements.</p>
                            </div>
                        </details>
                        <details>
                            <summary>Are fees paid by suppliers for goods sold?</summary>
                            <div class="faq-content">

                                <p>For a product listed on ProduceMart by suppliers, there are no fees paid to Producemart. The supplier is, however, responsible for paying the cost of inspection of their products for every transaction.</p>
                            </div>
                        </details>
                        <details>
                            <summary>Are fees paid by buyers for products bought?</summary>
                            <div class="faq-content">
                                <p>Buyers will pay the typical processing fee for a credit/debit card or bank transfer. In addition, ProduceMart charges a small service fee, which is a percentage of the order amount, for successful transactions only. Service fees are returned if the transaction doesn&#39;t go through for any reason.</p>
                            </div>
                        </details>
                        <details>
                            <summary>How are products shipped?</summary>
                            <div class="faq-content">
                                <p>ProduceMart works with its shipping partners to provide quotes and ship products via preferred sea, air or road freight. Buyers&#39; are responsible for accepting and paying shipping costs.
                                </p>
                            </div>
                        </details>
                        <details>
                            <summary>When is payment released?</summary>
                            <div class="faq-content">
                                <p>Only when an order has been inspected successfully is it released to the shipper. Upon receiving the products, the shipper will issue an airway bill or bill of laden confirming they have the order. Only then will Producemart release the order amount to the supplier.
                                </p>
                            </div>
                        </details> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
  </main>

  <mainFooter/>
</template>
<style scoped src="@/assets/css/styleFrontend.css"></style>
<style scoped src="@/assets/css/bootstrap.css"></style>
<script>
    import MainHeader from './mainHeader.vue'
    import SearchHeader from './searchHeader.vue'
    import MainFooter from './mainFooter.vue'
    import SearchInner from './searchInner.vue'
import { API } from '@/util/APIroute'
    export default {
      name: "ProduceMart",
      components:{
      'mainHeader': MainHeader,
      'searchHeader': SearchHeader,
      'mainFooter': MainFooter,
      'searchInner': SearchInner,
      },
      data(){
        return{
          user: JSON.parse(sessionStorage.getItem("user")) || '',
          faqs: []
        }
      },
      methods: {
        async getAllFaqs() {
            const res = await fetch(`${API}/get-faqs`, {
                method: "GET"
            })

            const {data, status} = await res.json()

            if(status){
                this.faqs = data
            }
        },
        logOut() {
          this.$store.dispatch("auth/logout");
          this.$router.push("/login");
        },
      },
      mounted(){
        window.scrollTo(0,0)
        this.getAllFaqs()
      }
    }
</script>
