import router from "@/router"
import Swal from "sweetalert2"
import { socket } from "./socket"

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 8000,
    showCloseButton: true,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
})

export const ToastAlert = (msg, icon)=>{
    if(msg || icon ){
        if(msg && msg.includes("Unauthorised!, You are already Logged Out!")){
            sessionStorage.removeItem("user")
            sessionStorage.clear()
            socket.disconnect()
            socket.close()
            router.push("/login")

            // return Toast.fire({
            //     title: msg,
            //     icon: icon
            // })
        }else{
            return Toast.fire({
                title: msg,
                icon: icon
            })
        }

    }
}

export const RefreshAlert = async() =>{
    return await Swal.fire({
        title: "Website Update Alert",
        text: "Please refresh the website for the latest updates and an improved experience.",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "Refresh"
    }).then((result) =>{
        if(result.isConfirmed){
           return setTimeout(()=>{
                router.go(0);
              });
        }
    })
}
