<template>
  <title>Payment Verification | ProduceMart</title>
  <div class="erroe_page_wrapper">
    <div class="errow_wrap">
      <div class="container text-center">
        <img class="mainLogo" src="https://res.cloudinary.com/dtx792i8k/image/upload/v1668426723/ProducemartImages/main-logo_n8mzfz.png" />
        <div class="col-md-8 offset-md-2 text-center">
          <p>
            {{ message ? message : "Please wait..." }}
          </p>
        </div>
        <!-- <div class="error_btn text-center mt-3">
          <router-link to="/login">
            <a class="default_btn theme_bg_6">Login</a>
          </router-link>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style scoped src="@/assets/vendors/themefy_icon/themify-icons.css"></style>
<style scoped src="@/assets/vendors/niceselect/css/nice-select.css"></style>
<style scoped src="@/assets/css/style.css"></style>
<script>
  import { API } from "@/util/APIroute";
import { ToastAlert } from "@/util/SweetAlertUtl";
export default {
  mounted() {
    window.scrollTo(0, 0);

    // let id = this.$route.query.id;
    // let token = this.$route.query.token;
    // console.log(id, token);
    // if (id && token) {
    //   fetch(
    //     `${API}/verify-email?id=${id}&token=${token}`
    //   )
    //     .then((res) => res.json())
    //     .then((data) => (this.message = data.message));
    // }
      this.checkRouteQuery()
  },
  data() {
    return {
      message: "",
    };
  },
  methods: {
    async checkRouteQuery(){
      // if(!this.token){
      //   this.token = localStorage.getItem('token')
      // }
      let query = this.$route.query
      // console.log(query)
        if (query.transactionId) {
          const body = {
              status: query.status,
              transactionId: query.transactionId,
              reference: query.reference,
              method: query.method
          }
          const res = await fetch(`${API}/escrow/verify`, {
          // params: {
          //   project_id: query.project_id,
          //   checkout_id: query.checkout_id
          // },
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
                // Authorization: this.token,
              },
          })
          const data = await res.json()
            if(data.status){
              this.message = `${data.message}, you will be redirected shortly`
              ToastAlert(data.message, "success")
              .then(() =>{
                  this.$router.push('/buyer-dashboard/view-open-order/' + data.data.quoteId)
              })
              // this.$router.push('/buyer-dashboard/view-open-order/' + this.quoteId)
              // this.getOrder();
            }else{
              ToastAlert(data.message, "error").then(()=>{
                  if(data.data.quoteId){
                    this.$router.push('/buyer-dashboard/view-open-order/' + data.data.quoteId)
                  }else{
                    this.$router.push('/buyer-dashboard/home')
                  }
              })
            }
        }

    },
  }
};
</script>
