<template>
    <div class="dashboard">
      <dash-sidebar :key="$route.path"/>
      <dash-navbar :key="$route.path"/>

      <!-- <router-view v-slot="{Component, route}">
          <div :key="route.path">
            <component :is="Component"  />
          </div>
      </router-view> -->
      <router-view  />
      <dash-footer/>

    </div>

</template>
<script>
import DashSidebar from './dash-sidebar.vue'
import DashNavbar from './dash-navbar.vue';
import DashFooter from './dash-footer.vue';
import { addUser, socket } from '@/util/socket';
import { API } from '@/util/APIroute';
import { ToastAlert } from '@/util/SweetAlertUtl';
export default {
      name: "ProduceMart",
      components:{
      'dash-sidebar': DashSidebar,

      },
    }
</script>
<script setup>
  import {onMounted, computed, ref, nextTick,watchEffect, onBeforeMount, onBeforeUnmount, onUnmounted} from 'vue';
// import { useStore } from 'vuex';
import Swal from "sweetalert2"
import router from '@/router';

// var store = useStore()
const user = ref(JSON.parse(sessionStorage.getItem('user')))

// watchEffect(() =>{
//     if(user.value === null || !user.value){
//       router.push('/login')
//     }
// })

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3500,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
})

if(!user.value){
  router.push("/login")
}

var events = ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load']

var warningTimer = ref(null);
var logoutTimer = ref(null);
// var warningZone = ref(false);
const warningMessage = () => {
  if(user.value && user.value.role === "buyer"){
    Swal.fire({
    icon: 'info',
    title: 'Are you still with us?',
    showConfirmButton: true,
    confirmButtonText: 'Yes',
  })
  }

}

const logoutUser = () => {
  if(user.value &&  user.value.role === "buyer" ){
    let timerInterval
        Swal.fire({
          title: 'Your Session has EXPIRED',
          html: 'You will be redirected in <b></b> seconds.',
          icon: "warning",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = (Swal.getTimerLeft() / 1000)
            }, 1000)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then(async (result)=>{
          if (result.dismiss === Swal.DismissReason.timer || result.dismiss === Swal.DismissReason.backdrop) {
            const userId = JSON.parse(sessionStorage.getItem("user"))._id
          const res = await fetch(`${API}/logout/${userId}`)
          const data = await res.json()
          if(data.status){
            ToastAlert({
              msg: data.message,
              icon: "success"
            }).then(()=>{
              // socket.emit('disconnect', userId)
              sessionStorage.removeItem('user')
              Swal.fire({
              title: 'You have now been logged out owing to inactivity',
              icon: "warning",
              showDenyButton: true,
              showCancelButton: false,
              confirmButtonText: 'Login',
              denyButtonText: `Home`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                router.push("/login")
              }
              if (result.isDenied) {
                router.push("/")
              }
              if(result.dismiss === Swal.DismissReason.backdrop){
                router.push("/")
              }
            })
            })
          }else{
            Toast.fire({
              title: data.message,
              icon: "error"
            })
          }

          }
        })
  }

}

var setTimers = () =>{

     warningTimer.value = setTimeout(warningMessage, 600000);
    logoutTimer.value = setTimeout(logoutUser, 900000);
    // warningZone.value = false


}

var resetTimer = () =>{
  clearTimeout(warningTimer.value)
  clearTimeout(logoutTimer.value)
  setTimers()
}

var resetTimers = () =>{
  clearTimeout(warningTimer.value)
  clearTimeout(logoutTimer.value)
  // setTimers()
}

const joinSocket = () =>{
  if(user.value){
    const payload={
      userId: user.value._id,
      user: "User"
    }

    addUser(payload)
    socket.on("user-joined", (data) =>{
      console.log(data)
    })
    socket.on("user-join-error", (data) =>{
      console.log(data)
    })
    socket.on("home", () =>{
        router.push("/login")
    })
  }
}



onMounted(() =>{
  events.forEach(function (event){
        window.addEventListener(event, resetTimer)
      })

      setTimers()
      joinSocket()
})
onUnmounted(() =>{
    events.forEach(function (event){
        window.removeEventListener(event, resetTimer)
      })

      resetTimers()
  })
</script>
