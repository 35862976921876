import { createRouter, createWebHistory } from "vue-router";


import EmailVerify from "../views/EmailVerify.vue";
import PaymentVerify from "../views/PaymentVerify.vue";
import BlogPage from "@/views/Blog.vue"
import BlogInnerPage from "@/views/BlogContent.vue"
import FAQ from "@/views/Faq.vue"

//////////Buyer Dashboard
import BuyerDash from "@/views/buyerDashboard/dashboard.vue";
import SupplierDash from "@/views/supplierDashboard/dashboard.vue"


//catchall 404
import NotFound from "../views/NotFound.vue";

const routes = [
  { path: "/", name: "Home", component: () => import("@/views/Home") },
  { path: "/about", name: "About", component: () => import("@/views/About") },
  // { path: "/supplier-audit", name: "SupplierTier", component: () => import("@/views/supplierTier") },
  {
    path: "/request-a-product",
    name: "RequestAProduct",
    component: () => import("@/views/RequestAProduct")
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("@/views/Products")
  },
  {
    path: "/SearchResult",
    name: "SearchResult",
    component: () => import("@/views/SearchResult")
  },
  {
    path: "/products/inner-product/:id",
    name: "InnerProductPage",
    component: () => import("@/views/InnerProductPage")
  },

  { path: "/faq", name: "Faq", component: FAQ },
  { path: "/blog", name: "Blog", component: BlogPage },
  {
    path: "/blog-content/:title",
    name: "BlogContent",
    component: BlogInnerPage,
    props: true
  },
  // {
  //   path: "/contact",
  //   name: "Contact",
  //   component: () => import("@/views/Contact")
  // },
  // { path: "/terms", name: "Terms", component: () => import("@/views/Terms") },
  { path: "/terms",
    redirect: (to) => {
      window.location.href = "https://drive.google.com/file/d/1qUofCRG59GKtwkrnpmFc1FAMQiDotVgM/view?usp=drive_link"
    }
  },
  {
    path: "/privacy-policy",
    redirect: (to) =>{
      window.location.href = "https://drive.google.com/file/d/1QWf-BdOs28023ypuX2QROj90yPFDVugb/view?usp=drive_link"
    }
  },

  // {
  //   path: "/privacy-policy",
  //   name: "Privacy",
  //   component: () => import("@/views/Privacy")
  // },
  // { path: "/privacy", redirect: "/privacy-policy" },
  { path: "/paia", redirect: (to) =>{
    window.location.href = "https://drive.google.com/file/d/1oIOvG-MRfTfQVJor6uU22FAvDvjkM3vy/view?usp=drive_link"
  } },

  { path: "/privacy",
    redirect: (to) =>{
    window.location.href = "https://drive.google.com/file/d/1QWf-BdOs28023ypuX2QROj90yPFDVugb/view?usp=drive_link"
  } },

  { path: "/verify-email", name: "EmailVerify", component: EmailVerify },
  { path: "/verify-payment", name: "PaymentVerify", component: PaymentVerify },
  //User Auth
  { path: "/login", name: "Login", component: () => import("@/views/userAuth/Login.vue") },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("@/views/userAuth/ForgotPassword.vue")
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/userAuth/ResetPassword.vue")
  },
  {
    path: "/registration",
    name: "Registration",
    component: () => import("@/views/userAuth/Registration.vue")
  },
  // {
  //   path: "/supplier-registration",
  //   name: "Supplier",
  //   component: () => import("@/views/userAuth/Supplier.vue")
  // },
  // {
  //   path: "/buyer-registration",
  //   name: "Buyer",
  //   component: () => import("@/views/userAuth/Buyer.vue")
  // },

  //////////Buyer Dashboard
  {
    path: "/buyer-dashboard",
    name: "BuyerDashboard",
    component: BuyerDash,
    children: [
      {
        path: "home",
        name: "DashBuyerHome",
        component: () => import("@/views/buyerDashboard/Home.vue")
      },
      //redirect to dashboard page
      { path: "/buyer-dashboard", redirect: "/buyer-dashboard/home" },
      //notifications
      {
        path: "all-notifications",
        name: "DashBuyerAllNotifications",
        component: () => import("@/views/buyerDashboard/AllNotifications.vue")
      },
      //Help videos
      {
        path: "help-video",
        name: "DashBuyerHelpVideo",
        component: () => import("@/views/buyerDashboard/HelpVideos.vue")
      },

      //addresses
      // {
      //   path: "add-new-address",
      //   name: "DashBuyerAddNewAddress",
      //   component: () => import("@/views/buyerDashboard/AddNewAddress")
      // },
      // {
      //   path: "view-address",
      //   name: "DashBuyerViewAllAddress",
      //   component: () => import("@/views/buyerDashboard/ViewAllAddress")
      // },
      // {
      //   path: "edit-address/:id",
      //   name: "DashBuyerEditAddress",
      //   component: () => import("@/views/buyerDashboard/EditAddress"),
      //   props: true
      // },

      //orders
      {
        path: "open-orders",
        name: "DashBuyerOrdersOpen",
        component: () => import("@/views/buyerDashboard/OrdersOpen.vue")
      },
      {
        path: "closed-orders",
        name: "DashBuyerOrdersClosed",
        component: () => import("@/views/buyerDashboard/OrdersClosed.vue")
      },
      // {
      //   path: "cancelled-orders",
      //   name: "DashBuyerOrdersCancelled",
      //   component: () => import("@/views/buyerDashboard/OrdersCancelled.vue")
      // },
      {
        path: "view-open-order/:id",
        name: "DashBuyerOrderViewOpen",
        component: () => import("@/views/buyerDashboard/OrderViewOpen.vue"),
        props: true
      },
      {
        path: "view-cancelled-order/:id",
        name: "DashBuyerOrderViewCancelled",
        component: () => import("@/views/buyerDashboard/OrderViewCancelled.vue"),
        prop: true
      },
      // {
      //   path: "order-review/review",
      //   name: "DashBuyerOrderReviewHere",
      //   component: () => import("@/views/buyerDashboard/OrderReviewHere.vue")
      // },
      // {
      //   path: "view-order/completed",
      //   name: "DashBuyerOrderViewCompleted",
      //   component: () => import("@/views/buyerDashboard/OrderViewCompleted.vue")
      // },

      //quotes
      // {
      //   path: "view-quotes-open",
      //   name: "DashBuyerQuotesOpen",
      //   component: () => import("@/views/buyerDashboard/QuotesOpen.vue")
      // },
      // {
      //   path: "view-quotes-closed",
      //   name: "DashBuyerQuotesClosed",
      //   component: () => import("@/views/buyerDashboard/QuotesClosed.vue")
      // },
      // {
      //   path: "view-quotes-cancelled",
      //   name: "DashBuyerQuotesCancelled",
      //   component: () => import("@/views/buyerDashboard/QuotesCancelled.vue")
      // },

      //Product Request
      {
        path: "product-request",
        name: "DashBuyerProductRequest",
        component: () => import("@/views/buyerDashboard/productRequest")
      },

      {
        path: "product-request/:id",
        name: "DashBuyerProductRequestDetails",
        component: () => import("@/views/buyerDashboard/productRequestDetails"),
        props: true
      },

      //settings
      {
        path: "edit-profile-details",
        name: "DashBuyerEditProfileDetails",
        component: () => import("@/views/buyerDashboard/EditProfileDetails.vue")
      },
      {
        path: "profile",
        name: "DashBuyerProfile",
        component: () => import("@/views/buyerDashboard/Profile.vue")
      },
      {
        path: "change-password",
        name: "DashBuyerChangePassword",
        component: () => import("@/views/buyerDashboard/ChangePassword.vue")
      }
      // {
      //   path: "2fa-auth-settings",
      //   name: "DashBuyerAuthSettings",
      //   component: () => import("@/views/buyerDashboard/AuthenticationSettings.vue")
      // }

    ]
  },

  //////////Supplier Dashboard
  {
    path: "/supplier-dashboard",
    name: "SupplierDashboard",
    component: SupplierDash,
    children:[
      {
        path: "home",
        name: "DashSupplierHome",
        component: () => import("@/views/supplierDashboard/Home.vue")
      },
      //redirect to dashboard page
      { path: "/supplier-dashboard", redirect: "/supplier-dashboard/home" },
      //notifications
      {
        path: "all-notifications",
        name: "DashSupplierAllNotifications",
        component: () => import("@/views/supplierDashboard/AllNotifications.vue")
      },
      //help videos
      {
        path: "help-video",
        name: "DashSupplierHelpVideo",
        component: () => import("@/views/supplierDashboard/HelpVideos.vue")
      },

      //Bid Product
      {
        path: "bid-product",
        name: "DashSupplierBidProduct",
        component: () => import("@/views/supplierDashboard/bidProduct")
      },
      {
        path: "bid-product/:id",
        name: "DashSupplierBidProductDetails",
        component: () => import("@/views/supplierDashboard/bidProductDetails"),
        prop: true
      },

      //orders
      {
        path: "open-orders",
        name: "DashSupplierOrdersOpen",
        component: () => import("@/views/supplierDashboard/OrdersOpen.vue")
      },
      {
        path: "closed-orders",
        name: "DashSupplierOrdersClosed",
        component: () => import("@/views/supplierDashboard/OrdersClosed.vue")
      },
      // {
      //   path: "cancelled-orders",
      //   name: "DashSupplierOrdersCancelled",
      //   component: () => import("@/views/supplierDashboard/OrdersCancelled.vue")
      // },
      {
        path: "view-open-order/:id",
        name: "DashSupplierOrderViewOpen",
        component: () => import("@/views/supplierDashboard/OrderViewOpen.vue"),
        prop: true
      },
      {
        path: "view-cancelled-order/:id",
        name: "DashSupplierOrderViewCancelled",
        component: () => import("@/views/supplierDashboard/OrderViewCancelled.vue"),
        prop: true
      },
      //products
      {
        path: "upload-product",
        name: "DashSupplierProductUpload",
        component: () => import("@/views/supplierDashboard/ProductUpload.vue")
      },

      {
        path: "published-products",
        name: "DashSupplierProductPublished",
        component: () => import("@/views/supplierDashboard/ProductPublished.vue")
      },
      {
        path: "pending-products",
        name: "DashSupplierProductPending",
        component: () => import("@/views/supplierDashboard/ProductPending.vue")
      },
      // {
      //   path: "rejected-products",
      //   name: "DashSupplierProductRejected",
      //   component: DashSupplierProductRejected,
      // },
      {
        path: "unavailable-products",
        name: "DashSupplierProductUnavailable",
        component: () => import("@/views/supplierDashboard/ProductUnavailable.vue")
      },
      {
        path: "draft-products",
        name: "DashSupplierProductDraft",
        component: () => import("@/views/supplierDashboard/ProductDraft.vue")
      },
      // {
      //   path: "continue-product-upload",
      //   name: "DashSupplierProductDraftContinue",
      //   component: () =>
      //     import("@/views/supplierDashboard/ContinueProductUpload.vue")
      // },
      {
        path: "view-products/:id",
        name: "DashSupplierProductViewPublished",
        component: () =>
          import("@/views/supplierDashboard/ProductViewPublished.vue"),
          prop: true
      },
      //certifications
      // {
      //   path: "certification",
      //   name: "DashSupplierCertification",
      //   component: () => import("@/views/supplierDashboard/certification")
      // },
      // {
      //   path: "uploadCertificate",
      //   name: "DashSupplierUploadCertification",
      //   component: () => import("@/views/supplierDashboard/uploadCertificate")
      // },
      // {
      //   path: "view-supplier-audit",
      //   name: "DashSupplierViewAudit",
      //   component: () => import("@/views/supplierDashboard/viewSupplierAudit")
      // },
      // {
      //   path: "view-audit-content/:id",
      //   name: "DashSupplierViewAuditContent",
      //   component: () =>
      //     import("@/views/supplierDashboard/viewSupplierAuditContent")
      // },
      // {
      //   path: "edit-audit/:id",
      //   name: "DashSupplierEditAudit",
      //   component: () => import("@/views/supplierDashboard/editAuditCertificate"),
      // },
      // {
      //   path: "request-audit",
      //   name: "DashSupplierRequestAudit",
      //   component: () => import("@/views/supplierDashboard/RequestAudit.vue")
      // },
      //sales analytics
      // {
      //   path: "sales-analytics",
      //   name: "DashSupplierSalesAnalytics",
      //   component: () => import("@/views/supplierDashboard/SalesAnalytics.vue")
      // },
      //addresses
      {
        path: "add-new-address",
        name: "DashSupplierAddNewAddress",
        component: () => import("@/views/supplierDashboard/AddNewAddress.vue")
      },
      {
        path: "view-address",
        name: "DashSupplierViewAllAddress",
        component: () => import("@/views/supplierDashboard/ViewAllAddress.vue")
      },
      {
        path: "edit-address/:id",
        name: "DashSupplierEditAddress",
        component: () => import("@/views/supplierDashboard/EditAddress.vue"),
        prop: true
      },
      //organization
      {
        path: "add-organization-details",
        name: "DashSupplierAddOrganizationDetails",
        component: () => import("@/views/supplierDashboard/AddOrganizationDetails.vue")
      },
      // {
      //   path: "view-organization-details",
      //   name: "DashSupplierViewOrganizationDetails",
      //   component: () => import("@/views/supplierDashboard/ViewOrganizationDetails.vue")
      // },
      // {
      //   path: "edit-organization-details/:id",
      //   name: "DashSupplierEditOrganizationDetails",
      //   component: () => import("@/views/supplierDashboard/EditOrganizationDetails.vue"),
      //   prop: true
      // },

      //settings
      {
        path: "edit-profile-details",
        name: "DashSupplierEditProfileDetails",
        component: () => import("@/views/supplierDashboard/EditProfileDetails.vue")
      },
      {
        path: "profile",
        name: "DashSupplierProfile",
        component: () => import("@/views/supplierDashboard/Profile.vue")
      },
      {
        path: "change-password",
        name: "DashSupplierChangePassword",
        component: () => import("@/views/supplierDashboard/ChangePassword.vue")
      }
      // {
      //   path: "2fa-auth-settings",
      //   name: "DashSupplierAuthSettings",
      //   component: () => import("@/views/supplierDashboard/AuthenticationSettings.vue")
      // }
    ]
  },

  //catchall 404
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
