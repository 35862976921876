import { countries } from "@/assets/countries"
export const currencyChanger = (currency) =>{
    if(currency === "usd"){
        return "$"
    }else if(currency === "eur" ){
        return "€"
    }else if(currency === "gbp"){
        return "£"
    }else{
        return currency
    }
}

export const countryFullname = (country_iso) =>{
    if(country_iso){
        const country = countries.filter((item) => item.country_iso ===country_iso)
    // console.log(country)
        return country[0].country_name
    }

}

export const versioning = () =>{
    const env = process.env.VUE_APP_NODE_ENV == 'production' ? 'prod' : process.env.VUE_APP_NODE_ENV == 'development' ? 'dev' : 'beta'

    const version = '1.0.0-'+ env

    return version
}
