<template>
  <title>
    {{ blog && blog.title }} - Blog | ProduceMart
  </title>
  <mainHeader />
    <section class="blog-details">
      <div class="container">
        <div class="row justify-content-center" v-if="blog">
          <div class="col-lg-11 details">
            <div class="blog-title text-center">
              <h1>{{ blog && blog.title }}</h1>
              <p>{{ format_date(blog && blog.createdAt) }} | By ProduceMart</p>
            </div>
            <div class="blog-image">
              <img :src="blog && blog.img_url" ondragstart="return false;" />
            </div>
            <div v-if="blog">
              <div class="note" v-html="blog.description"></div>
            </div>
            <div class="share-post text-center">
              <h4>Share Article</h4>
              <ShareNetwork
                  network="facebook"
                  :url="url"
                  :title="blog.title"
                  :description="blog.description"
                  :quote="blog.title"
                  :hashtags="'Producemart, blog '+ blog.title"
                >
                  <img src="https://res.cloudinary.com/dtx792i8k/image/upload/v1668431656/ProducemartImages/socialFacebook_ck7arn.png" />
              </ShareNetwork>
              <ShareNetwork
                  network="whatsapp"
                  :url="url"
                  :title="blog.title"
                  :description="blog.description"
                >
                  <img src="https://res.cloudinary.com/dtx792i8k/image/upload/v1668431649/ProducemartImages/socialWhatsapp_nfoeys.png" />
              </ShareNetwork>

              <ShareNetwork
                network="twitter"
                :url="url"
                :title="blog.title"
                :description="blog.description"
                twitter-user="producemart"
                :hashtags="'Producemart, blog '+ blog.title">
                <img src="https://res.cloudinary.com/dtx792i8k/image/upload/v1668431656/ProducemartImages/socialTwitter_q26mdg.png" />
            </ShareNetwork>
            </div>
            <div class="goback">
              <router-link to="/blog" class="routers"
                ><a
                  ><i class="bi bi-arrow-left-circle-fill"></i> Back to blog
                  page</a
                ></router-link
              >
            </div>
          </div>
          <!--Subscribe Newsletter-->
            <!-- <section class="subscribeNewsletter">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-10 subscribeBox">
                    <h1>Stay up to date with our latest news and product updates</h1>
                    <p>Join our mailing list today and stay updated!</p>
                    <form @submit.prevent="newsletterSubscribe" class="formInput">
                      <div class="row">
                        <div class="col-md-6 mb-3">
                          <label>Please introduce yourself?*</label>
                            <input type="text" required v-model="firstname" placeholder="Firstname" class="inputt">
                        </div>
                        <div class="col-md-6 mb-3">
                        <label>LastName</label>
                          <input type="text" v-model="lastname" required placeholder="Lastname" class="inputt">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label>How can we reach you? *</label>
                            <input v-model="email" type="email" required placeholder="Your email address" class="inputt">
                        </div>
                        <div class="col-md-6 mb-3">
                          <label>Are you a Buyer or Supplier? *</label>
                          <select v-model="group" required>
                            <option value="">Which option best describe you</option>
                            <option value="buyer">BUYER</option>
                            <option value="seller">SELER</option>
                          </select>
                        </div>
                        <div class="col-md-6 mb-3">
                            <label>Tell us about your business*</label>
                            <input type="text" v-model="company" required placeholder="Company Name*" class="inputt">
                        </div>
                        <div class="col-md-6 mb-3">
                            <label>Role/Position*</label>
                            <input type="text" v-model="role" required placeholder="Manager, CEO etc*" class="inputt">
                        </div>
                        <div class="col-md-12 mb-3">
                            <label>Where are you based out of?</label>
                            <select v-model="country" required>
                              <option value="">Please enter your country</option>
                              <option
                                v-for="(country, i) in countryList"
                                :value="country"
                                :key="i"
                              >
                                {{ country }}
                              </option>
                            </select>
                        </div>
                      </div>
                      <div class="mt-4">
                        <button type="submit">Subscribe</button>
                      </div>


                    </form>
                  </div>
                </div>
              </div>
            </section> -->
          <!--Subscribe Newsletter Ends-->
        </div>
        <div class="col-lg-12 mb-4 mt-2 text-center signuas" v-else>
          <span class="spinner-border spinner-border-sm"></span>
        </div>
      </div>
    </section>
  <mainFooter />
</template>
<style scoped src="@/assets/css/styleFrontend.css"></style>
<style scoped src="@/assets/css/bootstrap.css"></style>
<script>
import MainHeader from "./mainHeader.vue";
// import SearchHeader from "./searchHeader.vue";
import MainFooter from "./mainFooter.vue";
import { month } from "../assets/months";
import axios from 'axios'
import Swal from 'sweetalert2';
import { API } from "@/util/APIroute";
// import CountryList from "@/assets/countriesList.json"
import { ToastAlert } from '@/util/SweetAlertUtl';
import dayjs from 'dayjs';
export default {
  name: "ProduceMart",
  components: {
    mainHeader: MainHeader,
    // searchHeader: SearchHeader,
    mainFooter: MainFooter,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getBlog();
    // console.log(this.blog);
  },
  data() {
    return {
      // countryList: CountryList.sort(),
      blog: null,
      firstname: '',
      lastname: '',
      email: '',
      group: '',
      company: '',
      role: '',
      country: '',
      url: window.location.href
    };
  },
  methods: {
    format_date (value){
        if (value) {
        const date = dayjs(value);
        return date.format('dddd MMMM D, YYYY');
        }
    },
    async getBlog() {
      const title = this.$route.params.title;
      (this.blog = null), console.log(title);
      const res = await fetch(
        `${API}/getBlogByTitle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title: title }),
        }
      );
      const data = await res.json();
      // console.log(data.data);
      if(data.status){
        this.blog = data.data[0];
        // let d = new Date(this.blog.createdAt);
        // this.blog.createdAt =
        //   d.getDay() + "-" + month[d.getMonth()] + "-" + d.getFullYear();
      }else{
        return ToastAlert(data.message, "error")
      }

      //   console.log(this.blog.description);
    },
    newsletterSubscribe(){
      const formData = {
        "email": this.email,
        "firstname": this.firstname,
        "lastname": this.lastname,
        "company": this.company,
        "country": this.country,
        "role": this.role,
        "group": this.group,
      }
      axios.post(`${API}/subscriberBlog`, formData, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res) => {
        if(!res.data.status){
                    return ToastAlert(res.data.message, "error")
                  }
        Swal.fire({
          title: "Done!",
          icon: 'success',
          text: `You have successfully subscribe to our newsletter`,
          showConfirmButton: true,
          timer: 3500
        })
        this.resetForm()
      })
      .catch(e => {
        console.log(e)
      })
    },
    resetForm(){
      this.email = ''
      this.firstname = ''
      this.lastname = ''
      this.group = ''
      this.company = ''
      this.role = '',
      this.country = ''
    }
  },
};
</script>
<script setup>
import {useHead} from '@vueuse/head';
import { useRoute } from 'vue-router'
import {ref, reactive, computed} from 'vue'

const route = useRoute()
const blogContent = ref("");

const getBlogById = async() =>{
  const title = route.params.title
  const res = await fetch(
        `${API}/getBlogByTitle`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ title: title }),
        }
      );
      const data = await res.json()
      if(data.status){
        blogContent.value = data.data
      }else{
        return ToastAlert(data.message, "error")
      }
}
getBlogById()

if(blogContent.value){
  const siteData = reactive({
    title: `ProduceMart | ${blogContent.value.title}`,
    description: `${blogContent.value.description.slice(0, 400)}+ ' ...' + 'Read more'`
  })

  useHead({
    // title: computed(() => siteData.title),
    meta:[
      {
        name: 'description',
        content: computed(() => siteData.description)
      },
      {
        name: 'title',
        content: computed(() => siteData.title)
      },
      {
      property: "og:title",
      content: computed(() => siteData.title)
      },
      {
      property: "og:site_name",
      content: "ProduceMart"
      },
      {
      property: "og:url",
      content: `/blog-content/${blogContent.value.title}`
      },
      {
      property: "og:type",
      content: "website"
      },
      {
      property: "og:image",
      itemProp: "image",
      content: blogContent.value.img_url
      },
      {
      property: "og:locale",
      content: "en:GB"
      },
      {
      property: "og:image:width",
      content: "1200"
      },
      {
      property: "og:image:height",
      content: "600"
      },
      {
      name: "twitter:card",
      content: "summary_large_image"
      },
      {
      name: "twitter:image:alt",
      content: computed(() => siteData.title)
      },
      {
      name: "twitter:title",
      content: computed(() => siteData.title)
      },
      {
      name: "twitter:description",
      content: computed(() => siteData.description)
      },
      {
      name: "twitter:image",
      content: blogContent.value.img_url
      }
    ]
  })
}

</script>
<style scoped>
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation{
  z-index: 9999;
}
.swal2-container.swal2-center > .swal2-popup{
  margin-top: -10%;
}
</style>

