import {defineStore} from 'pinia'
import {API} from '@/util/APIroute'
// const Urlfeed = `${API}/orderFeedbackEmail`;
const Url = `${API}/contact-us`
export const setEmail = defineStore('email', {
    state: ()=>({
        name: '',
        email: '',
        subject: '',
        role: '',
        suggestion: '',
        message: '',
        status: null,
        error: null,
        token: null
    }),
    actions: {
      async sendToken(){
        try {
        this.error = null;
        var form = {
          response: this.token
        }

        const res = await fetch(`${API}/verify/recaptcha`, {
          method: 'POST',
          headers:{
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(form)
        })

        const data = await res.json()

        if(data.status){
          return true
        }else{
          return false
        }
        } catch (error) {
          this.error = error
          return false
        }

      },
        async send(){
            this.status = null;
            this.error = null;
            if(this.subject === 'getHelp'){
              var form = {
                name: this.name,
                email: this.email,
                subject: this.subject,
                message: this.message
            }
            }
            if(this.subject === 'feedback'){
              form = {
                name: this.name,
                email: this.email,
                subject: this.subject,
                suggestion: this.suggestion,
                role: this.role,
                message: this.message
            }
            }

            await fetch(Url, {
              method: 'POST',
              headers:{
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(form)
            })
            .then(async (res) =>{
              var data = await res.json()
              if(data.status){
                this.name ='';
                this.email = '';
                this.suggestion = '';
                this.role = '';
                this.message = '';
                this.status = data.message
              }else{
                this.name ='';
                this.email = '';
                this.suggestion = '';
                this.role = '';
                this.message = '';
                this.error = data.message
              }

            })
            .catch(error => {
              this.error = error
            })
          }
    }
})

export const setFeedback = defineStore('feedback', {
  state: ()=>({
      experience: '',
      suggestion: '',
      message: '',
      status: null,
      error: null,
      token: null,
      userId: null,
      id: null
  }),
  actions: {
      async send(){
          this.status = null;
          this.error = null;
           var form = {
              suggestion: this.suggestion,
              experience: this.experience,
              message: this.message
          }
          await fetch(`${API}/buyer/${this.userId}/orderFeedbackEmail` + `/${this.id}`, {
            method: 'POST',
            headers:{
              accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: this.token,
            },
            body: JSON.stringify(form)
          })
          .then(async (res) =>{
            var data = await res.json()
            if(data.status){
              this.suggestion = '';
              this.message = '';
              this.experience='';
              this.status = data.message
            }else{
              this.suggestion = '';
              this.message = '';
              this.experience='';
              this.error = data.message
            }

          })
          .catch(error => {
            this.error = error
          })
        }
  }
})
