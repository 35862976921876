<template>
  <!--Side Navbar-->
  <nav class="sidebar dark_sidebar" v-bind="$attrs">
    <div class="logo d-flex justify-content-between">
      <router-link rel="opener" class="large_logo" to="/"><img src="@/assets/img/logo.png"
          alt="ProduceMart Logo"></router-link>
      <router-link rel="opener" class="small_logo" to="/"><img src="@/assets/img/main-logo.png"
          alt="ProduceMart Logo"></router-link>
    </div>
    <ul id="sidebar_menu">
      <!--Dashboard-->
      <li class="">
        <router-link to="/buyer-dashboard/home">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/dashboard.png">
            </div>
            <div class="nav_title">
              <span :class="path == '/buyer-dashboard/home' && 'active-nav'">Dashboard</span>
            </div>
          </a>
        </router-link>
      </li>
       <!--Help Video-->
       <li class="">
        <router-link to="/buyer-dashboard/help-video">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/help.png" />
            </div>
            <div class="nav_title">
              <span :class="path == '/buyer-dashboard/help-video' && 'active-nav'">Help Videos</span>
              <span style="margin-left: 10px; "><IcRoundFiberNew style="font-size: 32px; color:dodgerblue"/></span>
            </div>
          </a>
        </router-link>
      </li>

      <li class="">
        <router-link to="/buyer-dashboard/product-request">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/cart.png">
            </div>
            <div class="nav_title">
              <span :class="path == '/buyer-dashboard/product-request' && 'active-nav'">Requested Products</span>
            </div>
          </a>
        </router-link>
      </li>
      <!--Notifications-->
      <li class="">
        <router-link to="/buyer-dashboard/all-notifications">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/notification.png">
            </div>
            <div class="nav_title">
              <span :class="path == '/buyer-dashboard/all-notifications' && 'active-nav'">Notifications</span>
            </div>
          </a>
        </router-link>
        <!-- <ul>
                    <li :class="path == '/buyer-dashboard/all-notifications' && 'active-nav'"> <router-link to="/buyer-dashboard/all-notifications"><a>All Notifications</a></router-link></li>
                </ul> -->
      </li>
      <!--Quotes-->
      <!-- <li
              class=""
            >
              <router-link to="#" class="has-arrow">
                <a aria-expanded="false">
                  <div class="nav_icon_small">
                    <img src="@/assets/img/menu-icon/quote.png" />
                  </div>
                  <div class="nav_title">
                    <span>Quotes</span>
                  </div>
                </a>
              </router-link>
              <ul>
                <li :class="path == '/buyer-dashboard/view-quotes-open' && 'active-nav'">
                  <router-link to="/buyer-dashboard/view-quotes-open"
                    ><a>Open Quotes</a></router-link
                  >
                </li>
                <li :class="path == '/buyer-dashboard/view-quotes-closed' && 'active-nav'">
                  <router-link to="/buyer-dashboard/view-quotes-closed"
                    ><a>Closed Quotes</a></router-link
                  >
                </li>
                <li :class="path == '/buyer-dashboard/view-quotes-cancelled' && 'active-nav'">
                  <router-link to="/buyer-dashboard/view-quotes-cancelled"
                    ><a>Cancelled Quotes</a></router-link
                  >
                </li>
              </ul>
            </li> -->
      <!--Orders-->
      <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/order.png">
            </div>
            <div class="nav_title">
              <span>Orders</span>
            </div>
          </a>
        </router-link>
        <ul>
          <li :class="path == '/buyer-dashboard/open-orders' && 'active-nav'"><router-link
              to="/buyer-dashboard/open-orders"><a>Open Orders</a></router-link></li>
          <li :class="path == '/buyer-dashboard/closed-orders' && 'active-nav'"><router-link
              to="/buyer-dashboard/closed-orders"><a>Closed Orders</a></router-link></li>
          <!-- <li :class="path == '/buyer-dashboard/cancelled-orders' && 'active-nav'"><router-link to="/buyer-dashboard/cancelled-orders"><a>Cancelled Orders</a></router-link></li> -->
          <!-- <li><router-link to="/buyer-dashboard/order-review"><a>Order Review</a></router-link></li> -->
        </ul>
      </li>
      <!-- <li class="">
                <router-link to="#" class="has-arrow">
                  <a aria-expanded="false">
                    <div class="nav_icon_small">
                      <img src="@/assets/img/menu-icon/address.png" />
                    </div>
                    <div class="nav_title">
                      <span>Addresses</span>
                    </div>
                  </a>
                </router-link>
                <ul>
                  <li
                    :class="
                      path == '/buyer-dashboard/add-new-address' && 'active-nav'
                    "
                  >
                    <router-link to="/buyer-dashboard/add-new-address"
                      ><a>Add New Address</a></router-link
                    >
                  </li>
                  <li
                    :class="path == '/buyer-dashboard/View-address' && 'active-nav'"
                  >
                    <router-link to="/buyer-dashboard/View-address"
                      ><a>View All Address</a></router-link
                    >
                  </li>
                </ul>
            </li> -->
      <!--Settings-->
      <li class="">
        <router-link to="#" class="has-arrow">
          <a aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/setting.png">
            </div>
            <div class="nav_title">
              <span>Settings</span>
            </div>
          </a>
        </router-link>
        <ul>
          <li :class="path == '/buyer-dashboard/edit-profile-details' && 'active-nav'"><router-link
              to="/buyer-dashboard/edit-profile-details"><a>Edit Profile Details</a></router-link></li>
          <li :class="path == '/buyer-dashboard/change-password' && 'active-nav'"><router-link
              to="/buyer-dashboard/change-password"><a>Change Password</a></router-link></li>
          <!-- <li :class="path == '/buyer-dashboard/2fa-auth-settings' && 'active-nav'"><router-link to="/buyer-dashboard/2fa-auth-settings"><a>Two Factor Auth</a></router-link></li> -->
        </ul>
      </li>

      <!--Log Out-->
      <li class="">
        <router-link to="#">
          <a @click="logOut" aria-expanded="false">
            <div class="nav_icon_small">
              <img src="@/assets/img/menu-icon/logout.png">
            </div>
            <div class="nav_title">
              <span>Log Out</span>
            </div>
          </a>
        </router-link>
      </li>
    </ul>
  </nav>
  <!--Ends(side navbar)-->
</template>
<style scoped src="@/assets/vendors/themefy_icon/themify-icons.css"></style>
<style scoped src="@/assets/vendors/niceselect/css/nice-select.css"></style>
<style scoped src="@/assets/css/style.css"></style>

<script>
import { head_script, del_script } from "@/assets/js/external";
import { socket } from "@/util/socket";
// import FoundationBurstNew from '~icons/foundation/burst-new';
import IcRoundFiberNew from '~icons/ic/round-fiber-new';
export default {
  name: 'dashboard',
  components: {
    "IcRoundFiberNew": IcRoundFiberNew
  },
  data() {
    return {
      user: JSON.parse(sessionStorage.getItem("user")),
      path: this.$route.path
    };
  },
  computed: {
    currentUser() {
      const user = JSON.parse(sessionStorage.getItem("user"));
      console.log(user);
      if (user) {
        console.log(user)
        return user;
      }
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  },

  methods: {
    logOut() {
      // if(user){
      //   socket.emit('disconnect', user._id)
      // }
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted() {
    window.scrollTo(0, 0)

    //     let externalScriptJquery = document.createElement('script')
    // let externalScriptMetisMenu = document.createElement('script')
    // let externalScriptCustom = document.createElement('script')

    // externalScriptJquery.setAttribute(
    //   "src",
    //   "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"
    // );
    // externalScriptMetisMenu.setAttribute(
    //   "src",
    //   "https://cdn.statically.io/gh/NathTimi/Mart-script/main/metisMenu.js"
    // );
    // externalScriptCustom.setAttribute(
    //   "src",
    //   "https://cdn.statically.io/gh/morshud/JsFiles/main/custom.js"
    // );

    //     document.head.appendChild(externalScriptJquery)
    // document.head.appendChild(externalScriptMetisMenu)
    // document.head.appendChild(externalScriptCustom)

    head_script('/metisMenu.js');
    head_script('/custom.js');

  },
  unmounted() {
    del_script('/custom.js');
    del_script('/metisMenu.js');
  },
}
</script>
