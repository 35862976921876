<template>
  <title>Blog | ProduceMart</title>
  <mainHeader />


  <!--Page Breadcrumb-->
  <section class="blog pageBreadcrumb blogPageBread">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10 text-center">
          <div class="textHead">
            <p class="subhead">ProduceMart</p>
            <h2>Blog</h2>
            <p class="belowHead">Read our latest news and updates</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!--Page Breadcrumb Ends-->

  <!--Blog Content-->
  <main class="blogContent">
    <section class="blogs">
      <div class="container">
        <div class="row" v-if="blogs.length > 0">
          <div class="col-md-6 mb-1" style="display: grid;" v-for="(blog, i) in filteredBlogs" :key="blog._id"  >
            <div class="card mb-3">
              <div class="row g-0">
                <div class="col-lg-5">
                  <div class="imgHouse">
                    <!-- <img
                      :src="blog.img_url"
                      class="img-fluid rounded-start"
                      draggable="false"
                    /> -->
                    <v-lazy-image
                      :src="blog.img_url"
                      :src-placeholder="logo"
                      class="img-fluid rounded-start"
                      draggable="false"
                    />
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="card-body">
                    <h5 class="card-title">{{ blog.title }}</h5>
                    <p
                      class="card-text"
                      v-html="blog.description.slice(0, 70) + '...'"
                    ></p>
                    <router-link
                      :to="'/blog-content/' + blog.title"
                      class="linkRead"
                      >Read More <i class="bi bi-arrow-right-short"></i
                    ></router-link>
                    <p class="card-text">
                      <small class="text-muted">{{
                        format_date(blog.createdAt)
                      }}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="col-lg-12 mb-4 mt-2 text-center signuas" v-else>
          <span class="spinner-border spinner-border-sm"></span>
        </div>
      </div>
      <div class="col-md-12 text-center paginationSection" v-if="blogs.length > size">
            <button v-if="current_page > 1" @click="prevPage" class="arrows">
              <i class="bi bi-arrow-left-circle-fill"></i>
            </button>
            <button v-if="(blogs.length>0)" class="btnButton active">{{current_page}}</button>
            <button v-if="(current_page*size) < blogs.length" @click="nextPage" class="arrows">
              <i class="bi bi-arrow-right-circle-fill"></i>
            </button>
          </div>
    </section>
  </main>

  <mainFooter />
</template>
<style scoped src="@/assets/css/styleFrontend.css"></style>
<style scoped src="@/assets/css/bootstrap.css"></style>
<style scoped>

.v-lazy-image {
  filter: blur(10px);
  transition: filter 0.7s;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.paginationSection{
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.paginationSection .arrows{
  background-color: transparent !important;
  border: none !important;
  position: relative !important;
  top: 5px !important;
}

.paginationSection .arrows i{
  font-size: 30px !important;
  color: #979797 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.paginationSection .btnButton{
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 16px !important;
  color: #fff !important;
  background-color: #87ec90 !important;
  border: none !important;
  border-radius: 5px !important;
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.paginationSection button.active{
  background-color: #000 !important;
  color: #fff !important;
}
</style>
<script>
import MainHeader from "./mainHeader.vue";
// import SearchHeader from "./searchHeader.vue";
import MainFooter from "./mainFooter.vue";
// import SearchInner from "./searchInner.vue";
// import { month } from "../assets/months";
import { API } from "@/util/APIroute";
import { ToastAlert } from "@/util/SweetAlertUtl";
import dayjs from 'dayjs'
import VLazyImage from "v-lazy-image";
import logo from "@/assets/img/logoOld.png"
export default {
  name: "ProduceMart",
  components: {
    mainHeader: MainHeader,
    // searchHeader: SearchHeader,
    mainFooter: MainFooter,
    VLazyImage: VLazyImage
    // searchInner: SearchInner,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getAllBlogs();
  },
  data() {
    return {
      logo: logo,
      blogs: [],
      user: JSON.parse(sessionStorage.getItem("user")) || '',
      size: 4,
      current_page: 1,
    }
  },
  computed:{
    filteredBlogs(){
      return this.blogs.filter((row, index) => {
            let start = (this.current_page-1)*this.size;
            let end = this.current_page*this.size;
            if(index >= start && index < end) return true;
          });
    }
  },
  methods: {
    format_date (value){
        if (value) {
        const date = dayjs(value);
        return date.format('dddd MMMM D, YYYY');
        }
    },
    nextPage() {
      if((this.current_page*this.size) < this.blogs.length) this.current_page++;
      window.scrollTo(0,0)
    },
    prevPage() {
      if(this.current_page > 1) this.current_page--;
      window.scrollTo(0,0)
    },
    async getAllBlogs() {
      // this.blogs = null;
      const res = await fetch(`${API}/getAllBlog`, {
        method: "GET",
        headers:{
          "Content-Type": "application/json",
        }
      });
      const data = await res.json();
      if (data.status) {
        // console.log(data.data)
        this.blogs = data.data;
        // this.blogs = this.blogs.map((val) => {
        //   let d = new Date(val.createdAt);
        //   val.createdAt =
        //     d.getDay() + "-" + month[d.getMonth()] + "-" + d.getFullYear();
        //   return val;
        // });
      }else{
        return ToastAlert(data.message, "error")
      }

      // console.log(this.blogs);
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
  },
};
</script>
