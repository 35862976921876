import {reactive, reative} from 'vue'
import {io} from 'socket.io-client'
import {API} from './APIroute'

export const state = reactive({
    connected: false,

})

export const socket = io(API, {
    transports: ['websocket'],
    reconnection: true,
})

export const connect = () => {
    socket.connect()
    socket.on("connect", () => {
        state.connected = true
    })
}

export const disconnect = (userId) => {
    // socket.emit('disconnect', userId)
    socket.disconnect()
    state.connected = false
}

export const addUser = (payload) => {
    socket.emit('add-user', payload)
}

socket.on('disconnect', () => {
    console.log('disconnected from socket.io')
    state.connected = false
})


export const sendMessage = (payload) =>{
    socket.emit("send-msg", payload)
}
