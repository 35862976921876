<template>
    <header class="mainHeader">
        <form class="searchHeader" @submit.prevent="searchQuery">
            <input type="search" v-model="searchProduct" placeholder="Search for products">
            <button type="submit"><i class="bi bi-search"></i></button>
        </form>
    </header>
</template>
<style scoped src="@/assets/css/styleFrontend.css"></style>
<style scoped src="@/assets/css/bootstrap.css"></style>
<script>
export default {
  mounted(){
    window.scrollTo(0,0)
  },
  data(){
    return{
        searchProduct: "",
    }
  },
  // watch:{
  //   searchProduct(){
  //     this.searchQuery()
  //   }
  // },
  methods: {
    searchQuery(){
        //console.log(this.searchProduct)
        // console.log(this.$route.path)
        if(this.$route.path === '/SearchResult'){
          this.$router.replace({
            name:'SearchResult',
            query:{
              keyword:this.searchProduct,
            }
          })
          setTimeout(() =>{
            this.$router.go()
          }, 2000)

        }else{
          this.$router.replace({
            name:'SearchResult',
            query:{
              keyword:this.searchProduct,
            }
          })
        }


        // this.$router.push(`/SearchResult?keyword=${this.searchProduct}`)
    }
  }
}
</script>
