//import Vue from "vue";
import { createStore } from "vuex";

import { auth } from "./auth.module";

import { admin } from "./admin.module";


export default createStore({
  modules: {
    auth,
    admin,
  },
});
