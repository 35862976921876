import axios from "axios";
// import spHeader from "./super-authHeader";
import { API } from "@/util/APIroute";
// import { ToastAlert } from "@/util/SweetAlertUtl";
import { socket } from "@/util/socket";

// const API = "https://producemart.herokuapp.com/";
//const API = "http://localhost:3000/";

class AuthService {
  async login(user)
    {
    const response = await axios.post(API + "/signin",  {
      email: user.email,
      password: user.password,
    }, {
      validateStatus: (status) => {
        return status >= 200 && status < 503; // default
      },
    },
   );
    if (response.data.token) {
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          firstname: response.data.user.firstname,
          lastname: response.data.user.lastname,
          token: response.data.token,
        })
      );
    }
    return response.data;
  }

  async loginSuperAdmin(user) {
    const response = await axios.post(API + "/adminLogin", {
      email: user.email,
      password: user.password,
    }, {
      validateStatus: (status) => {
        return status >= 200 && status < 503; // default
      },
    });
    if (response.data.token) {
      sessionStorage.setItem(
        "SuperAdmin",
        JSON.stringify({
          token: response.data.token,
        })
      );
    }
    return response.data;
  }

  async logout() {
    const userId = JSON.parse(sessionStorage.getItem("user"))._id
    socket.emit("remove-user", userId)
    socket.disconnect()
    socket.close()
    // sessionStorage.removeItem("user");
    // sessionStorage.removeItem("tradeToken");
    sessionStorage.clear()
    // localStorage.removeItem("token");
    return axios.get(
      `${API}/logout/${userId}`, {
        validateStatus: (status) => {
          return status >= 200 && status < 503; // default
        },
      }
    )
  }

  async logoutAdmin() {
    const userId = JSON.parse(sessionStorage.getItem("user"))._id
    socket.emit("remove-user", userId)
    socket.disconnect()
    socket.close()
    // sessionStorage.removeItem("SuperAdmin");
    // sessionStorage.removeItem("user");
    sessionStorage.clear()
    // localStorage.removeItem("token");
    return axios.get(
      `${API}/logout/${userId}`, {
        validateStatus: (status) => {
          return status >= 200 && status < 503; // default
        },
      }
    )
  }

  register(user) {
    return axios.post(API + "/register-buyer",
    {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone_no: `+27${user.phone_no}`,
      password: user.password,
      // address: user.address
    },
    {
      validateStatus: (status) => {
        return status >= 200 && status < 503; // default
      },
    });
  }
  supplier(user) {
    return axios.post(API + "/register-supplier",
    {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone_no: `+27${user.phone_no}`,
      password: user.password,
      // address: user.address
    },
    {
      validateStatus: (status) => {
        return status >= 200 && status < 503; // default
      },
    });
  }

  verify(id, token) {
    return axios.get(
      `${API}/email-verification/${id}/${token}`, {
        validateStatus: (status) => {
          return status >= 200 && status < 503; // default
        },
      }
    );
  }

  // getOrganizers() {
  //   return axios.get(API + "/dashboard/profile", {validateStatus: (status) => {
  //     return status >= 200 && status < 503; // default
  //   }, headers: spHeader() });
  // }
}

export default new AuthService();
