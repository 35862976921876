<template>

  <router-view v-slot="{Component, route}">
    <transition name="fade" mode="out-in">
      <keep-alive>
        <div :key="route.path">
          <component :is="Component"  />
        </div>
      </keep-alive>

    </transition>
</router-view>

<SpeedInsights />
</template>
<script setup>
import {onMounted} from 'vue';
import {state, connect, socket} from './util/socket'
import router from './router';
import { SpeedInsights } from '@vercel/speed-insights/vue';

  onMounted(() =>{
    // let externalScriptCustom = document.createElement("script");
    //     externalScriptCustom.setAttribute(
    //       "src",
    //       "https://cdn.cookie-script.com/s/465e467041df20efd1029aa787374d5f.js"
    //     );

    //     document.head.appendChild(externalScriptCustom);

        connect()
        socket.on('connect', () => {
          if(state.connected){
            console.log("Socket.io connected successfully")
          }else{
            console.log("Socket.io failed to connect")
          }
        })
      //   socket.on("home", () =>{
      //   router.push("/login")
      // })
  })


</script>

<style>
  @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

  #app{
    font-family:  "Poppins", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *{
    font-family:  "Poppins", sans-serif !important;
  }

</style>


