import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import Vuex from "vuex";
import store from "./store";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueApexCharts from "vue3-apexcharts";
import VueSocialSharing from "vue-social-sharing";
import { createPinia } from "pinia";
// import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueGtag from "vue-gtag"
import { createHead } from "@vueuse/head"
import { inject } from '@vercel/analytics';




// import the necessary css file
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "@/assets/css/main.css"

inject();

const pinia = createPinia()

const app = createApp(App);


// window.sitemap = function () {
//   const routes = router
//     .getRoutes()
//     .map(r => r.path)
//     .filter(r => !r.includes(':')) // removes routes with params
//     .map(r => `<url><loc>https://za.producemart.com${r}</loc></url>`)
//   console.log(`
//     <?xml version='1.0' encoding='UTF-8'?>
//     <urlset xmlns='http://www.sitemaps.org/schemas/sitemap/0.9'>
//         ${routes.join('\n')}
//     </urlset>
//   `)
// }

// document.feature


app

  .use(CKEditor)
  .use(VueSocialSharing)
  .use(router)
  .use(VueApexCharts)
  .use(Vuex)
  .use(pinia)
  .use(store)
  .use(createHead())
  .use(VueGtag, {
    config: {id: "G-3DPBQDMTD0"}
  })
  .mount("#app");


